/*----------------------------------------*/
/*  28. SOLUTION CSS START
/*----------------------------------------*/


.solution {
  &__area {
    padding-left: 100px;
    position: relative;

    @media #{$md} {
      padding-left: 10px;
      padding-top: 150px;
    }

    @media #{$sm} {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 90px;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1.3fr 1.7fr 1fr;

    @media #{$md} {
      grid-template-columns: repeat(12, 1fr);
    }

    @media #{$sm} {
      grid-template-columns: 1fr;
    }
  }

  &__left {
    @media #{$md} {
      grid-column: 1/7;
    }
  }

  &__mid {
    padding-left: 80px;

    @media #{$xxl} {
      padding-left: 50px;
    }

    @media #{$lg} {
      padding-left: 20px;
    }

    @media #{$md} {
      padding-left: 0;
      grid-column: 7/-1;
      grid-row: 1;
    }

    @media #{$sm} {
      padding-left: 0;
      grid-row: 1;
    }

    p {
      max-width: 580px;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.4;
      color: var(--black-3);
      padding-left: 190px;
      position: relative;

      @media #{$xxl} {
        padding-left: 110px;
        max-width: 480px;
      }

      @media #{$xl} {
        padding-left: 80px;
      }

      @media #{$lg} {
        padding-left: 50px;
      }

      @media #{$md} {
        padding-left: 50px;
      }

      @media #{$sm} {
        padding-left: 80px;
        padding-bottom: 50px;
      }

      &::before {
        position: absolute;
        content: "";
        width: 140px;
        height: 1px;
        left: 0;
        top: 10px;
        background-color: var(--black-2);

        @media #{$xxl} {
          width: 100px;
        }

        @media #{$xl} {
          width: 70px;
        }

        @media #{$lg} {
          width: 40px;
        }

        @media #{$md} {
          width: 40px;
        }

        @media #{$sm} {
          width: 70px;
        }
      }
    }
  }

  &__right {
    padding-top: 260px;

    @media #{$md} {
      padding-top: 0;
      grid-column: 1/-1;
      grid-row: 2;
      padding-bottom: 30px;
    }

    @media #{$sm} {
      padding-top: 60px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 150px;
    line-height: 1;
    color: var(--black-2);
    padding-top: 280px;
    padding-bottom: 20px;

    @media #{$xxl} {
      font-size: 120px;
      padding-top: 200px;
    }

    @media #{$xl} {
      padding-top: 80px;
      font-size: 100px;
    }

    @media #{$lg} {
      padding-top: 80px;
      font-size: 72px;
    }

    @media #{$md} {
      padding-top: 60px;
      font-size: 60px;
    }

    @media #{$sm} {
      padding-top: 30px;
      font-size: 36px;
    }
  }

  &__img {
    &-1 {
      display: inline-block;
      position: relative;

      @media #{$lg} {
        margin-left: 30px;
      }

      img {
        @media #{$xxl} {
          max-width: 400px;
        }

        @media #{$xl} {
          max-width: 300px;
        }

        @media #{$lg} {
          max-width: 240px;
        }

        @media #{$md} {
          max-width: 260px;
        }

        @media #{$sm} {
          max-width: 300px;
        }
      }

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: calc(100% + 10px);
        left: 40px;
        top: 0;
        border: 1px solid var(--white-6);
        border-radius: 0 0 290px 230px;
        z-index: -1;

        @media #{$sm} {
          left: 20px;
        }
      }
    }

    &-2 {
      position: relative;
      float: right;
      margin-top: 60px;

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        right: 30px;
        top: 0;
        border: 1px solid var(--white-6);
        border-radius: 200px;
        z-index: -1;
      }
    }

    &-3 {
      position: relative;
      float: right;
      margin-top: 60px;

      img {
        @media #{$xxl} {
          max-width: 360px;
        }

        @media #{$xl} {
          max-width: 300px;
        }

        @media #{$lg} {
          max-width: 220px;
        }

        @media #{$md} {
          max-width: 260px;
        }

        @media #{$sm} {
          max-width: 300px;
        }
      }

      &::after {
        position: absolute;
        content: "";
        width: calc(100% + 20px);
        height: 100%;
        right: 0;
        top: -30px;
        border: 1px solid var(--white-6);
        border-radius: 280px 0 0 200px;
        z-index: -1;
      }
    }
  }

  &__btm {
    @media #{$sm} {
      padding-top: 40px;
    }

    ul {
      text-align: right;
    }

    li {
      display: inline;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.4;
      color: var(--black-2);
      padding-right: 40px;
      position: relative;

      &:last-child {
        padding-right: 0;
      }

      @media #{$sm} {
        padding-right: 30px;
      }

      &::after {
        position: absolute;
        content: "";
        width: 5px;
        height: 5px;
        left: -20px;
        top: 12px;
        background-color: var(--black-2);
      }

      &:first-child {
        &::after {
          position: unset;
        }
      }
    }
  }


  &__shape {
    img {
      position: absolute;
    }

    .shape {
      &-1 {
        left: 80%;
        top: 25%;
      }

      &-2 {
        left: 10%;
        top: 75%;
      }

      &-3 {
        left: 33%;
        top: 45%;
      }

      &-4 {
        left: 72%;
        top: 70%;
      }

      &-5 {
        top: 42%;
        left: 68%;
        z-index: -1;
      }
    }
  }
}