/*----------------------------------------*/
/*  00. PRELOADER CSS START
/*----------------------------------------*/


@keyframes wcLoading {
  0% {
    transform: scaleY(0.1);
    background: var(--white);
  }

  50% {
    transform: scaleY(1);
    background: var(--primary);
  }

  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}

.preloader {
  width: 100%;
  height: 100vh;
  background-color: var(--black-2);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99999;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  .bar {
    width: 6px;
    height: 60px;
    background: var(--white);
    display: inline-block;
    transform-origin: bottom center;
    animation: wcLoading 1.5s ease-in-out infinite;
  }

  .bar1 {
    animation-delay: 0.1s;
  }

  .bar2 {
    animation-delay: 0.2s;
  }

  .bar3 {
    animation-delay: 0.3s;
  }

  .bar4 {
    animation-delay: 0.4s;
  }

  .bar5 {
    animation-delay: 0.5s;
  }

  .bar6 {
    animation-delay: 0.6s;
  }

  .bar7 {
    animation-delay: 0.7s;
  }

  .bar8 {
    animation-delay: 0.8s;
  }
}