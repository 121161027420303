.land-icons{
  padding-bottom: 10px;
}
.animation__char_come{
   animation: wcfadeUp 1s .6s forwards
}
@media only screen and (max-width: 767px) {
  .service-v5.pt-140 {
    padding-top: 555px;
    padding-bottom: 40px;
  }
}
.pt-80 {
  padding-top: 100px;
  padding-bottom: 80px;
}
.plot-heading {
  font-size: 56px;
  line-height: 65px;
}
.portfolio__service .sec-title {
  max-width: 500px;
  text-indent: 80px;
  text-transform: uppercase;
  opacity: 0;
  font-weight: 600;
}
.portfolio__service .sec-text p {
  max-width: 726px;
  font-size: 18px;
  line-height: 1.4;
  color: var(--black-13);
  padding-top: 50px;
  opacity: 0;
}
.portfolio__service-item:hover .land-icons svg {
  filter: invert(1);
}
@media (min-width: 320px) and (max-width: 425px) {
    .pt-80 {
      padding-top: 580px;
      padding-bottom: 80px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .plot-heading {
      font-size: 30px;
      line-height: 48px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .portfolio__service .sec-text p {
    max-width: 726px;
    font-size: 18px;
    line-height: 1.5 !important;
    color: var(--black-13);
    padding-top: 50px;
    opacity: 0;
}
.pt-80 {
  padding-top: 33px;
  padding-bottom: 80px;
}
.portfolio__service-item .ps-title {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 1.4;
  text-transform: none;
  color: var(--black-2);
  padding-bottom: 20px;
  transition: all 0.3s;
}
.land-icons svg {
  height: 45px;
  width: 45px;
}
h2.sec-title.animation__char_come.plot-heading.txt-heading-txt {
  margin-top: 30px;
  font-size: 35px;
  line-height: 48px;
}
section.portfolio__service.service-v5.pt-80.pb-140 {
  padding-left: 20px;
  padding-right: 20px;
}
}
@media (min-width: 1024px) and (max-width: 1600px) {
  .pt-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}
.portfolio__service .sec-text {
  background-size: 100%;
  background-position: 0 20px;
}
.portfolio__service-item .ps-title {
  font-weight: 500;
  font-size: 17px !important;
  line-height: 1.4;
  text-transform: none;
  color: var(--black-2);
  padding-bottom: 20px;
  transition: all 0.3s;
}
.land-icons svg {
  height: 45px;
  width: 45px;
}
}
@media (max-width: 1440px) {
  .pt-80 {
    padding-top: 40px;
    padding-bottom: 80px;
}
}

@media (max-width: 414px) {
  .pt-80 {
    padding-top: 650px !important;
    padding-bottom: 80px;
}
}