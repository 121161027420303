/*----------------------------------------*/
/*  27. TEAM CSS START
/*----------------------------------------*/


.team {
  &__area {
    background-color: var(--black-2);
    overflow: hidden;

    @media #{$xxl} {
      padding-left: 62px;
    }

    @media #{$xl} {
      padding-left: 62px;
    }

    .sec {
      &-title {
        color: var(--white);

        &-wrapper {
          text-align: center;
          padding-bottom: 60px;
        }
      }

      &-sub-title {
        color: var(--white);
      }
    }

    &-6 {
      background-image: url(../../imgs/bg/shape.png);
      background-repeat: no-repeat;
      background-position: 100px 180px;
      overflow: hidden;

      @media #{$xxl} {
        background-position: 0 180px;
        padding-left: 62px;
      }

      @media #{$xl} {
        background-position: 0 180px;
        padding-left: 62px;
      }

      @media #{$lg} {
        background-position: 0 180px;
      }

      @media #{$md} {
        .line {
          padding-top: 150px;
        }
      }

      .sec {
        &-title {
          line-height: 0.9;
          font-size: 120px;
          padding-bottom: 30px;

          @media #{$xl} {
            font-size: 90px;
          }

          @media #{$lg} {
            font-size: 72px;
          }

          @media #{$md} {
            font-size: 60px;
          }

          @media #{$sm} {
            font-size: 48px;
            padding-top: 90px;
          }

          &-wrapper {
            max-width: 520px;
            padding-bottom: 60px;

            p {
              font-size: 18px;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }

  &__slider {

    @media #{$xxxl} {
      margin-left: 100px;
    }

    @media #{$md} {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__slide {
    @media #{$sm} {
      padding: 0 20px;
    }

    &:nth-child(even) {
      padding-top: 100px;

      @media #{$lg} {
        padding-top: 50px;
      }

      @media #{$md} {
        padding-top: 50px;
      }

      @media #{$sm} {
        padding-top: 0;
      }
    }

    img {
      width: 100%;
    }
  }

  &__member {
    &-name {
      font-weight: 500;
      font-size: 24px;
      line-height: 1.3;
      color: var(--white);
      padding-top: 20px;

      &-6 {
        @extend .team__member-name;
        color: var(--black-2);
      }

      &-7 {
        font-weight: 500;
        font-size: 80px;
        line-height: 1;
        color: var(--black-2);
        padding-bottom: 30px;

        @media #{$xl} {
          font-size: 60px;
          padding-bottom: 20px;

          br {
            display: none;
          }
        }

        @media #{$lg} {
          font-size: 60px;
          padding-bottom: 20px;

          br {
            display: none;
          }
        }

        @media #{$lg} {
          font-size: 60px;
        }

        @media #{$md} {
          font-size: 48px;

          br {
            display: none;
          }
        }

        @media #{$sm} {
          font-size: 36px;
          padding-bottom: 20px;
        }
      }
    }

    &-role {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.3;
      color: var(--gray-2);

      &-6 {
        @extend .team__member-role;
        color: var(--black-3);
      }

      &-7 {
        font-weight: 400;
        font-size: 24px;
        line-height: 1;
        color: var(--black-3);
        font-style: italic;
        padding-bottom: 15px;
        margin-bottom: 40px;
        position: relative;

        @media #{$lg} {
          font-size: 20px;
        }

        @media #{$md} {
          font-size: 18px;
        }

        @media #{$sm} {
          font-size: 18px;
        }

        &::after {
          position: absolute;
          content: "";
          width: 150px;
          height: 1px;
          left: 0;
          bottom: 0;
          background-color: var(--black-2);

          @media #{$md} {
            left: calc(50% - 75px);
          }

          @media #{$sm} {
            left: calc(50% - 75px);
          }
        }

        span {
          color: var(--black-2);
          font-weight: 500;
        }
      }
    }
  }

  &__join-btn {
    background-color: var(--black-2);
    padding: 300px 0;

    @media #{$xl} {
      padding: 200px 0;
    }

    @media #{$lg} {
      padding: 100px 0;
    }

    @media #{$md} {
      padding: 90px 0;
    }

    @media #{$sm} {
      padding: 60px 0;
    }

    #btn_wrapper,
    .btn_wrapper {
      width: 360px;
      height: 360px;
      margin: 0 auto;
    }

    a {
      position: relative;
      color: var(--gray-2);
      font-size: 20px;
      width: 342px;
      height: 342px;
      margin: 0 auto;

      @media #{$md} {
        width: 300px;
        height: 300px;
        padding: 60px;
      }

      @media #{$sm} {
        width: 220px;
        height: 220px;
        padding: 35px;
      }

      &:hover {
        span {
          width: 800px;
          height: 800px;
        }
      }

      i {
        position: absolute;
        right: 130px;
        top: 150px;

        @media #{$md} {
          top: 130px;
          right: 88px;
        }

        @media #{$sm} {
          right: 50px;
          top: 90px;
        }
      }
    }
  }

  &__btm {
    overflow: hidden;

    .sec-title-wrapper {
      padding-left: 48%;
      background-image: url(../../imgs/bg/snake.png);
      background-repeat: no-repeat;
      background-position: 1px -150px;

      @media #{$xl} {
        background-size: 42%;
      }

      @media #{$lg} {
        background-size: 400px;
      }

      @media #{$md} {
        padding-left: 30%;
        background-size: 200px;
        background-position: 1px -50px;
      }

      @media #{$sm} {
        background-size: 0px;
        padding-left: 0;
        padding-bottom: 0;
      }
    }

    p {
      font-size: 22px;
      padding-top: 30px;
      @include zi(1);

      @media #{$lg} {
        font-size: 20px;
      }

      @media #{$md} {
        font-size: 18px;
      }

      @media #{$sm} {
        font-size: 18px;
      }
    }
  }

  &__detail {
    overflow: hidden;

    @media #{$xxl} {
      .pb-140 {
        padding-bottom: 40px;
      }
    }

    @media #{$xl} {
      .pb-140 {
        padding-bottom: 40px;
      }
    }

    @media #{$lg} {
      padding-top: 120px;
      position: relative;

      .pb-140 {
        padding-bottom: 10px;
      }
    }

    @media #{$md} {
      text-align: center;

      .pb-140 {
        padding-bottom: 10px;
      }
    }

    @media #{$sm} {
      text-align: center;

      .pb-140 {
        padding-bottom: 0;
      }
    }

    .pt-120 {
      @media #{$xl} {
        padding-top: 60px;
      }

      @media #{$lg} {
        padding-top: 10px;
      }
    }

    p {
      font-size: 18px;
      line-height: 1.4;
      padding-bottom: 20px;
    }

    .work-title {
      font-weight: 400;
      font-size: 18px;
      line-height: 1.5;
      color: var(--black-2);
      padding-bottom: 20px;
    }

    &-page {
      position: relative;
    }
  }

  &__member {
    &-img {
      overflow: hidden;
      position: absolute;
      width: 770px;
      height: 100vh;
      left: -192px;

      @media #{$xxl} {
        left: 0;
      }

      @media #{$xl} {
        left: 0;
      }

      @media #{$lg} {
        position: unset;
        width: 100%;
        height: auto;
      }

      @media #{$md} {
        position: relative;
        width: 100%;
        height: auto;
        left: 0;
        z-index: 1;
        margin-top: 150px;
      }

      @media #{$sm} {
        position: relative;
        width: 100%;
        height: auto;
        left: 0;
        z-index: 1;
        margin-top: 150px;
      }

      img {
        position: absolute;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 102%;
        margin-left: auto;
        margin-right: auto;

        @media #{$xxl} {
          left: 0;
          max-width: 80%;
        }

        @media #{$xl} {
          left: 0;
          max-width: 70%;
        }

        @media #{$lg} {
          position: unset;
          max-width: 100%;
        }

        @media #{$md} {
          position: unset;
          max-width: 100%;
        }

        @media #{$sm} {
          position: unset;
          max-width: 100%;
        }
      }
    }

    &-work {
      padding-top: 40px;

      li {
        display: inline-block;
        padding-right: 10px;

        a {
          display: block;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.5;
          color: var(--black-2);
          padding: 5px 12px;
          border: 1px solid var(--white-4);
          border-radius: 30px;
          transition: all 0.3;

          &:hover {
            background-color: var(--black-2);
            color: var(--white);
          }
        }
      }
    }

    &-social {
      padding-top: 40px;

      li {
        display: inline-block;
        padding-right: 15px;

        a {
          font-size: 18px;
          color: var(--gray-15);

          &:hover {
            color: var(--black-2);
          }
        }
      }
    }
  }

}