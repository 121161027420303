@media (min-width: 320px) and (max-width: 767px) {
  h2.footer__widget-title.contact-title {
    margin-left: -5px;
  }
  li {
    width: 296px;
  }
  .footer__inner {
    background-color: transparent;
    padding-left: 0;
    padding-top: 60px;
    grid-template-columns: 1fr;
    padding-left: 15px;
    padding-right: 15px;
  }
  .footer__contact {
    display: block;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-2);
    padding-left: 30px;
    padding-right: 30px;
  }
  .footer__widget {
    grid-column: 1 / 12 !important;
    padding: 85px 0 0px 0 !important;
  }
  .footer__widget-2 {
    grid-column: 1 / 12 !important;
    padding: 85px 0 0px 0 !important;
    margin-left: 0px !important;
  }
  .footer__widget-3 {
    grid-column: 1 / 12 !important;
    padding: 85px 0 0px 0 !important;
    margin-left: 0px !important;
  }
  .footer__widget-4 {
    grid-column: 1 / 12 !important;
    padding: 50px 30px 50px 30px !important;
  }
  footer #btn_wrapper,
  footer .btn_wrapper {
    margin: 0 auto;
    margin-left: 168px !important;
  }
  a.wc-btn-primary.btn-hover.btn-item.btn-footer {
    margin-left: -235px;
}
}

@media (min-width: 768px) and (max-width: 991px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 770px;
  }
  footer.footer__area {
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer__widget {
    grid-column: 1 / 4 !important;
    padding: 85px 0 0px 0 !important;
  }
  .footer__widget-2 {
    grid-column: 5 / 8 !important;
    padding: 85px 0 0px 0 !important;
    margin-left: 0px !important;
  }
  .footer__widget-3 {
    grid-column: 9 / 12 !important;
    padding: 85px 0 0px 0 !important;
    margin-left: 0px !important;
  }
  .footer__widget-4 {
    grid-column: 1 / 12 !important;
    padding: 50px 30px 50px 30px !important;
    margin-left: 70px !important;
  }
  .footer-full-width {
    background-color: var(--black-2);
  }
  footer #btn_wrapper,
  footer .btn_wrapper {
    margin: 0 auto;
    margin-left: 168px !important;
  }
  a.wc-btn-primary.btn-hover.btn-item.btn-footer {
    height: 140px !important;
    width: 140px !important;
    margin-top: 48px;
    margin-left: -50px;
}
}
@media (min-width: 1025px) and (max-width: 1599px) {
  footer.footer__area {
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer__widget {
    grid-column: 1 / 4;
    padding: 0 0 50px 0;
  }
  .footer__widget-2 {
    grid-column: 5 / 8;
    padding: 0 0 50px 0;
  }
  .footer__widget-3 {
    grid-column: 9 / 12;
    padding: 0 0 50px 0;
  }
  .footer__widget-4 {
    grid-column: 1 / 12;
    padding: 50px 30px 50px 30px;
    margin-left: 70px;
  }
  .footer-full-width {
    background-color: var(--black-2);
  }
  footer #btn_wrapper,
  footer .btn_wrapper {
    margin: 0 auto;
    margin-left: 254px !important;
  }
  .main-menu-3 > li {
    display: inline-block;
    padding: 0 25px;
    margin: 0;
  }
  /* .cta__content-2 #btn_wrapper .btn-item,
  .cta__content-3 #btn_wrapper .btn-item,
  .cta__content-2 .btn_wrapper .btn-item,
  .cta__content-3 .btn_wrapper .btn-item {
    top: 45px;
    height: 200px !important;
    width: 200px !important;
  }
  .cta__area-3 .pt-150 {
    padding-top: 210px;
  } */
}

ul.footer__useful__link li a {
  font-weight: 400;
  font-size: 16px;
  color: var(--gray-2)!important;
  line-height: 24px;
}
.footer__useful__link li{
  margin-bottom: 15px;
  margin-left: 20px;
}
ul.footer__useful__link li a:before {
  color: var(--blue-color);
  font-family: fontAwesome;
  font-size: 21px;
  left: -19px;
  position: absolute;
}
ul.footer__useful__link li a:hover {
  color: white !important;
}
.footer__contact li a.phone {
  color: var(--gray-2)!important;
  position: relative;
}
@media (max-width: 1600px){
  footer.footer__area {
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer__widget {
    grid-column: 1 / 4;
    padding: 0 0 50px 0;
  }
  .footer__widget-2 {
    grid-column: 5 / 8;
    padding: 0 0 50px 0;
  }
  .footer__widget-3 {
    grid-column: 9 / 12;
    padding: 0 0 -1px 0;
  }
  .footer__widget-4 {
    grid-column: 1 / 12;
    padding: 50px 30px 50px 30px;
    margin-left: 70px;
  }
  .footer-full-width {
    background-color: var(--black-2);
  }
  footer #btn_wrapper,
  footer .btn_wrapper {
    margin: 0 auto;
    margin-left: 254px !important;
  }
  .main-menu-3 > li {
    display: inline-block;
    padding: 0 25px;
    margin: 0;
  }
  /* .cta__content-2 #btn_wrapper .btn-item,
  .cta__content-3 #btn_wrapper .btn-item,
  .cta__content-2 .btn_wrapper .btn-item,
  .cta__content-3 .btn_wrapper .btn-item {
    top: 45px;
    height: 200px !important;
    width: 200px !important;
  }
  .cta__area-3 .pt-150 {
    padding-top: 210px;
  } */
}

@media (min-width: 1400px) and (max-width: 1440px) {
  .footer__inner {
    padding-top: 100px;
  }
  .footer__widget-2 {
    grid-column: span 2;
    padding: 140px 0 130px;
  }
  .footer__widget {
    grid-column: span 4;
    padding: 140px 0 130px;
  }
  .footer__widget-3 {
    grid-column: span 2;
    margin-right: -30px;
    padding: 140px 0 130px;
  }
  .footer__widget-4 {
    background-color: #1d1d1d;
    background-color: var(--black-4);
    grid-column: span 4;
    grid-row: span 2;
    margin-left: 60px;
    padding: 135px 70px 30px;
    text-align: center;
  }
  footer #btn_wrapper,
  footer .btn_wrapper {
    margin: 0 auto;
    margin-left: 0px !important;
  }
  /* .cta__content-3 .btn_wrapper {
    position: relative;
    z-index: 1;
    width: 240px;
    height: 240px;
    margin-left: auto;
    margin-right: auto;
  } */
  .cta__content-2 #btn_wrapper .btn-item, .cta__content-2 .btn_wrapper .btn-item, .cta__content-3 #btn_wrapper .btn-item, .cta__content-3 .btn_wrapper .btn-item {
    height: 150px !important;
    top: -111px !important;
    width: 150px !important;
}
}

/* @media (max-width: 1024px) {
  .footer__inner {
    padding-top: 0px;
  }
  .footer__widget-2 {
    margin-left: -100px;
  }
  .footer__widget-3 {
    margin-left: -90px;
  }
  .footer__widget-4 {
    background-color: #1d1d1d;
    background-color: var(--black-4);
    grid-column: span 4;
    grid-row: span 2;
    margin-left: 0px;
    padding: 135px 70px 30px;
    text-align: center;
  }
} */

.footer__useful__link .svg-bollet-dholera .svg-pad-t{
  padding-top: 0px;
}
.svg-pad-t-p svg {
  filter: brightness(99);
}
.footer__widget {
  grid-column: span 3;
  padding: 140px 0 130px;
}
.footer__widget-2 {
  grid-column: span 3;
  padding: 140px 0 130px;
}
.svg-social-media{
  filter: brightness(99);
  margin-right: 20px;
}
.social_media_dainty{
  margin-left: 20px;
}

@media (min-width: 1400px) and (max-width: 1440px){
  .cta__content-2 #btn_wrapper .btn-item,
  .cta__content-3 #btn_wrapper .btn-item,
  .cta__content-2 .btn_wrapper .btn-item,
  .cta__content-3 .btn_wrapper .btn-item {
    top: 0px;
    height: 200px !important;
    width: 200px !important;
  }
  .footer__widget-2 {
    grid-column: span 2 !important;
    padding: 140px 0 130px;
}
.footer__widget-3 {
  grid-column: span 3;
  margin-right: -30px;
  padding: 140px 0 130px;
}
}
@media (min-width: 1024px) and (max-width: 1399px ){
  .footer__widget-3{
    margin-top: 130px;
  }
}

@media (width: 1425px) {
  .footer__widget-2 {
    grid-column: span 2 !important;
    padding: 140px 0 130px;
}
.footer__widget-3 {
  grid-column: span 3;
  margin-right: -30px;
  padding: 140px 0 130px;
}
}
@media (min-width: 1900px) and (max-width: 1920px) {
  .footer__widget-2 {
    grid-column: span 2;
    padding: 140px 0 130px;
}
.footer__widget-3 {
  grid-column: span 3;
  margin-right: -30px;
  padding: 140px 0 130px;
}
}