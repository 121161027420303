.header__nav-icon-3 .search-icon {
  display: none;
}
.viewport{
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  position: fixed;
}
::-moz-selection {
  /*Code for Firefox */
  color: white;
  background: -webkit-linear-gradient(150deg, #9DC446, #409704);
    background: linear-gradient(150deg, #e8662b, #409704);
    border-radius: 10px;
}

::selection {
  color: #ad042b;
  background: -webkit-linear-gradient(150deg, #9DC446, #409704);
  background: linear-gradient(150deg, #9DC446, #409704);
  border-radius: 10px;
}
/*-------------------------*/

::-webkit-scrollbar {
  width: 0.6vw;
  border-radius: 10px;
  background: #e7e7e7;
}

::-webkit-scrollbar-thumb {
  background: -webkit-linear-gradient(150deg, #9DC446, #409704);
  background: linear-gradient(150deg, #9DC446, #409704);
  border-radius: 0px;
  border-radius: 10px;
}
.contact__form {
  position: absolute !important;
  z-index: 9;
  top: 0px;
  left: 61.5%;
  top: 2.8%;
  background-color: #fffffff2;
  padding-top: 45px;
  padding-left: 45px;
  padding-right: 45px;
  width: 23.5%;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.dis-contents {
  display: flex;
}
.contact__form input,
.contact__form textarea {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black-2);
  border: none;
  border-bottom: 1px solid var(--gray);
  padding: 15px 0;
  transition: all 0.3s;
  outline: 0;
  position: relative;
  z-index: 1;
  background: transparent;
  margin-bottom: 15px !important;
}
.contact__form #btn_wrapper,
.contact__form .btn_wrapper {
  margin-top: -40px !important;
  margin-bottom: -25px !important;
}
.wc-btn-primary,
.wc-btn-black,
.wc-btn-light,
.wc-btn-pink,
.wc-btn-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 147px;
  height: 147px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  text-transform: capitalize;
  color: var(--black-3);
  border: 1px solid var(--gray);
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
}
.enquire-now {
  font-weight: 700;
  padding-bottom: 20px;
}
.service-icon {
  height: 70px;
  width: 70px;
}

.portfolio__service-item:hover img {
  padding-bottom: 10px;
  filter: invert(1);
}

.mt-70 {
  margin-top: 70px;
}

.w130 {
  width: 130px;
}
.w120 {
  width: 120px;
}
.w150 {
  width: 150px;
}
.workflow__area-3 {
  background-color: var(--pink-4);
  padding-top: 60px;
  direction: ltr;
  overflow-x: scroll !important;
}
.workflow__wrapper-3 {
  width: 400%;
  height: 100vh !important;
  display: flex;
  flex-wrap: no-wrap;
}
.workflow__area-3 {
  background-color: var(--pink-4);
  padding-top: 60px;
  direction: ltr;
  overflow-x: scroll;
  scrollbar-width: none;
}
.footer-full-width {
  background-color: var(--black-4);
}

.faq__list-3 .accordion-button {
  background-color: transparent;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  color: var(--black-2);
  padding-left: 0;
  padding-right: 20px !important;
  border-radius: 0;
}
.wc-btn-primary:hover span {
  width: 412px !important;
  height: 400px !important;
}
.wc-btn-pink:hover span {
  width: 400px !important;
  height: 415px !important;
}
.dainty-header-logo {
  height: 70px;
}
.btn-scroll {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9991;
  border-radius: 100px;
  color: var(--white);
  transition: all 0.3s;
  /* display: none; */
  /* mix-blend-mode: exclusion; */
  background-color: black;
}
.error-message {
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.feature__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr !important;
  grid-gap: 15px !important;
}
.cointainer-custom {
  max-width: 1400px !important;
}
.feature__item {
  border-right: 1px solid var(--black-2);
  padding: 145px 15px !important;
}
.info-txt {
  font-weight: 800 !important;
}
.footer-contact-icons {
  height: 20px;
  color: white !important;
  filter: invert(1);
  margin-left: -32px;
}
.txt-heading-txt {
  text-transform: initial !important;
}
h2.footer__widget-title.contact-title {
  margin-left: -30px;
}
.whatsapp-stick{
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
}
.whatsapp-img{
  height: 50px;
  width: 50px;
}
.hightlight-seo{
  color: #ad042b;
  font-weight: bold;
}
@media (min-width: 1280px) and (max-width: 1360px) {
  .contact__form {
    position: absolute !important;
    z-index: 9;
    top: 0px;
    left: 61.5%;
    top: 0.7%;
    background-color: #fffffff2;
    padding-top: 45px;
    padding-left: 45px;
    padding-right: 45px;
    width: 23.5%;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .wc-btn-primary,
  .wc-btn-black,
  .wc-btn-light,
  .wc-btn-pink,
  .wc-btn-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px !important;
    height: 110px !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    text-transform: capitalize;
    color: var(--black-3);
    border: 1px solid var(--gray);
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .contact__form {
    position: absolute !important;
    z-index: 9;
    top: 0px;
    left: 61.5%;
    top: 1.3% !important;
    background-color: #fffffff2;
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    width: 23.5%;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .wc-btn-primary,
  .wc-btn-black,
  .wc-btn-light,
  .wc-btn-pink,
  .wc-btn-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px !important;
    height: 110px !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    text-transform: capitalize;
    color: var(--black-3);
    border: 1px solid var(--gray);
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
  }
}
/* @media (min-width: 1024px) and (max-width: 1200px) {
  .contact__form {
    position: absolute !important;
    z-index: 9;
    top: 0px;
    left: 61.5%;
    top: 1% !important;
    background-color: #fffffff2;
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    width: 24.5%;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .wc-btn-primary, .wc-btn-black, .wc-btn-light, .wc-btn-pink, .wc-btn-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px !important;
    height: 90px !important;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 1.3;
    text-transform: capitalize;
    color: var(--black-3);
    border: 1px solid var(--gray);
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
}
.contact__form input,
.contact__form textarea {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black-2);
  border: none;
  border-bottom: 1px solid var(--gray);
  padding: 15px 0;
  transition: all 0.3s;
  outline: 0;
  position: relative;
  z-index: 1;
  background: transparent;
  margin-bottom: 0px !important;
}
.enquire-now {
  font-weight: 700;
  padding-bottom: 0px;
}
} */
@media (min-width: 1380px) and (max-width: 1440px) {
  .contact__form {
    position: absolute !important;
    z-index: 9;
    top: 0px;
    left: 61.5%;
    top: 1%;
    background-color: #fffffff2;
    padding-top: 45px;
    padding-left: 45px;
    padding-right: 45px;
    width: 23.5%;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact__form {
    position: absolute !important;
    z-index: 9;
    top: 0px;
    left: 57.5%;
    top: 0.8%;
    background-color: #fffffff2;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    width: 37.5%;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .enquire-now {
    font-weight: 700;
    padding-bottom: 0px;
    font-size: 20px;
  }
  .contact__form input {
    margin-bottom: 0px !important;
  }
  .wc-btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 1.3;
    text-transform: capitalize;
    color: var(--black-3);
    border: 1px solid var(--gray);
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
  }

  .btn_wrapper {
    margin-left: -33px !important;
  }
  .wc-btn-primary {
    width: 95px !important;
    height: 95px !important;
    font-size: 12px !important;
  }
  .w-100 {
    width: 100% !important;
    height: 444px;
  }
  .header__inner-3 {
    padding: 10px !important;
  }
  footer #btn_wrapper,
  footer .btn_wrapper {
    margin: 0 auto;
    margin-left: 50px !important;
  }
}

@media (max-width: 425px) {
  .contact__form {
    position: absolute !important;
    z-index: 9;
    /* top: 0px; */
    left: 0%;
    top: 302px;
    background-color: #fffffff2;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    width: 99%;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: 50px;
  }
  .contact__form .btn_wrapper {
    margin-top: 0px !important;
  }
  img.d-block.w-100 {
    height: 300px;
  }
  .mt-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
@media (max-width: 375px) {
  .contact__form {
    position: absolute !important;
    z-index: 9;
    left: 0%;
    top: 320px;
    background-color: #fffffff2;
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    width: 99%;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: 25px;
  }
  .contact__form .btn_wrapper {
    margin-top: 0px !important;
  }
  img.d-block.w-100 {
    height: 320px;
  }
  .mt-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .contact__form {
    position: absolute !important;
    z-index: 9;
    left: 0%;
    top: 309px;
    background-color: #fffffff2;
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    width: 99%;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: 65px;
  }
  .contact__form .btn_wrapper {
    margin-top: 0px !important;
  }
  img.d-block.w-100 {
    height: 320px;
  }
  .mt-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
