.price__area .sec-title {
    text-transform: uppercase;
    padding-bottom: 60px;
    font-size: 56px;
    line-height: 65px;
  font-weight: 600;

}
.below-dholera-content{
    padding-bottom: 50px !important;
    width: 87%;
    font-size: 18px !important;
    line-height: 1.8;
    text-align: justify;
}
.dholera-sir{
    padding-bottom: 60px;
    font-size: 50px;
    text-transform: initial !important;
}
.two-sec {
    padding: 130px 50px;
}
.two-sec img {
    width: 97%;
}
.accordion-body p {
    font-size: 18px;
    line-height: 1.8em;
}
@media (min-width: 320px) and (max-width: 425px){
    .two-sec {
        padding: 80px 30px 15px 30px;
    }
    .below-dholera-content {
        padding-bottom: 50px !important;
        width: 100%;
        font-size: 18px !important;
        line-height: 1.8;
        text-align: justify;
    }
    .price__area .sec-title {
        text-transform: uppercase;
        padding-bottom: 20px;
        font-size: 29px;
        line-height: 48px;
    }
    .two-sec img {
        width: 97%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .animation__service_page {
        padding-left: 15px;
        padding-right: 15px;
    }
    section.feature__area-2.pt-130 {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (min-width: 426px) and (max-width: 500px){
    .two-sec {
        padding: 100px 15px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .price__area .sec-title {
        text-transform: uppercase;
        padding-bottom: 60px;
        font-size: 35px;
        line-height: 48px;
    }
    .two-sec {
        padding: 81px 35px;
    }
    .col-xxl-6.col-xl-6.col-lg-6.dholera-top {
        width: 100% !important;
    }
}
@media (min-width: 992px) and (max-width: 1024px) {
    .col-xxl-6.col-xl-6.col-lg-6.dholera-top {
        width: 100% !important;
    }
}

@media (max-width: 1440px) {
    .col-xxl-6.col-xl-6.col-lg-6.dholera-top {
        width: 50%;
    }
}