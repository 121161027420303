
.service__area-6 .right-content {
    width: 57% !important;
    /* padding-left: 280px !important; */
}
.heading-dholera-content-txt{
    line-height: 1.4 !important;
  font-weight: 600;

}
.dholera-content-txt{
    line-height: 1.8 !important;
    text-align: justify !important;
}
.feature__item p {
    color: var(--black-2);
    font-size: 18px;
    line-height: 1.4;
    text-align: center !important;
    color: var(--black-2);
}
.sec-text p {
    font-size: 18px !important;
    line-height: 1.8em !important;
}
.svg-icons-dholera{
    margin-bottom: 35px;
    text-align: center;
}
.svg-icons-dholera svg{
    height: 75px;

}
.smart-txt{
    font-weight: 600;
}

@media (min-width: 320px) and (max-width: 767px){
    .service__area-6 .right-content {
        width: 97% !important;
        padding-left: 12px !important;
    }
    .service__area-6 .mid-content{
        display: flex;
        width: 100%;
    }
    .feature__list {
        display: grid;
        grid-template-columns: 1fr 1fr !important;
        grid-gap: 15px !important;
        text-align: center;

    }
    .service-mid-img{
        height: 400px !important;
        top: 100px;
        width: 100% !important;
    }
    .service__items-6{
        padding-top: 0px !important;
    }
    h2.sec-title.title-anim {
        font-size: 30px;
        line-height: 48px;
    }
    .custom-h3{
        font-size: 30px;
        line-height: 48px;
    }
    .feature__item {
        /* border-right: 1px solid var(--black-2); */
        padding: 40px 15px !important;
    }
    .service__area-6 .mid-content {
        height: 70vh;
        position: relative;
    }
    .svg-icons-dholera svg {
        height: 55px;
    }
    .feature__item p {
        color: var(--black-2);
        font-size: 18px;
        line-height: 1.4;
        text-align: center !important;
        color: var(--black-2);
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .mid-content {
        display: block !important;
      }
      .content-wrapper {
        flex-direction: column !important;
      }
      .service__area-6 .mid-content {
        width: 100%;
        height: 100vh;
        position: relative;
      }
      .service__area-6 .right-content {
        width: 100% !important;
        padding-left: 30px !important;
        padding-right: 30px;
        margin-top: 96px;
    }
    h2.sec-title.title-anim {
        line-height: 48px;
    }
    .feature__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr !important;
        grid-gap: 15px !important;
    }
    .feature__item {
        border-right: none !important;
        padding: 50px 15px !important;
    }
    .service__title-6 {
        font-size: 35px;
        line-height: 48px;
    }
    .sec-title {
        font-size: 35px;
    }
    section.feature__area-2.pt-130 {
        padding-left: 20px;
        padding-right: 20px;
    }
    .container.g-0.line.pt-140.pb-130 {
        padding-left: 20px;
        padding-right: 20px;
    }
    section.service__area-6 {
        padding-top: 0px;
    }
    
}
@media (min-width: 1024px) and (max-width: 1599px) {
  
      .content-wrapper {
        flex-direction: column;
      }
      .service__area-6 .mid-content {
        width: 100% !important;
        height: 100vh;
        position: relative;
      }
      .service__item-6 {
        height: auto;
        flex-direction: column;
        display: flex;
        grid-template-columns: 2fr 3fr;
        grid-column-gap: 30px;
        margin-bottom: 60px;
    }
    .service__area-6 {
        padding-top: 0px;
        padding-left: 0px;
    }
    .service__item-6 p {
        max-width: 1011px;
        font-size: 18px;
        line-height: 1.4;
        color: var(--gray-2);
        padding-bottom: 30px;
    }
    .service__area-6 .right-content {
        width: 100% !important;
    }
    .img-tab-container{
        width: 100% !important;
    }
    .animation__service_page {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 80px;
    }
    .sec-title {
        font-size: 50px;
    }
    .feature__text {
        padding-left: 0px;
    }
}
@media (max-width: 1600px){
    .service__area-6 .mid-content {
        width: 55%;
        height: 100vh;
        position: relative;
    }
    .service__area-6 .mid-content .service__image img {
        width: auto;
        height: 100vh !important;
    }
}
@media(max-width : 1366px){
    .service__area-6 .mid-content {
        width: 100%;
        height: 100vh;
        position: relative;
    }
}
@media (max-width: 1440px) {
    .content-wrapper {
        flex-direction: row;
      }
}
@media (max-width: 1680px) {
    .service__area-6{
        padding-left: 0px;
    }
}