/*----------------------------------------*/
/*  35. PRICE CSS START
/*----------------------------------------*/



.price {
  &__area {
    background-color: var(--pink-3);

    &.pb-140 {
      @media #{$md} {
        padding-bottom: 100px;
      }
    }

    .sec {
      &-title {
        text-transform: uppercase;
        padding-bottom: 60px;

        @media #{$sm} {
          padding-bottom: 40px;
        }
      }

      &-sub-title {
        text-transform: uppercase;
      }
    }
  }

  &__table {
    margin-top: 80px;
  }

  &__item {
    display: flex;
    gap: 40px;
    background: var(--white);
    padding: 40px 30px;
    margin-bottom: 20px;
    border-radius: 9px;
    position: relative;

    @media #{$lg} {
      gap: 20px;
    }

    @media #{$sm} {
      gap: 30px;
    }

    &:first-child {
      background-color: var(--black-2);

      .price {
        &__icon {
          span {
            color: var(--black-2);
            border-color: var(--pink-2);
            background-color: var(--pink-2);
          }
        }

        &__type {
          color: var(--gray-2);
          border: 1px solid var(--gray-14);
        }

        &__title {
          color: var(--white);
        }

        &__info {
          p {
            color: var(--gray-2);
          }
        }

        &__amount {
          p {
            color: var(--white);
          }
        }
      }
    }

    .best-value {
      font-weight: 500;
      font-size: 12px;
      line-height: 10px;
      color: var(--white);
      text-transform: uppercase;
      padding: 8px 12px;
      border-radius: 5px;
      background-color: var(--pink-2);
      position: absolute;
      top: -15px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: block;
      width: 40px;
      height: 40px;
      color: var(--gray-2);
      border-radius: 5px;
      border: 2px solid var(--gray-13);
      background-color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 20px;
      }
    }
  }

  &__type {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--black-6);
    border: 1px solid var(--black-6);
    display: inline-block;
    padding: 5px 12px;
    border-radius: 30px;
    margin-bottom: 15px;
  }

  &__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 1.5;
    text-transform: uppercase;
    color: var(--black-2);
    padding-bottom: 5px;
  }

  &__info {
    p {
      color: var(--black-6);
    }
  }

  &__amount {
    @media #{$sm} {
      position: absolute;
      top: 0;
      right: 30px;
      padding-top: 30px;
    }

    p {
      display: flex;
      align-items: baseline;
      font-weight: 400;
      font-size: 36px;
      line-height: 1.3;
      color: var(--black-2);

      span {
        font-size: 16px;
      }
    }
  }

  &__btn {
    padding-top: 50px;

    @media #{$sm} {
      padding-top: 0;
      margin-top: 40px;
    }

    a {
      margin: 0 auto;
      border-color: var(--black-2);
    }
  }
}