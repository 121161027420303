/*----------------------------------------*/
/*  38. FOOTER CSS START
/*----------------------------------------*/

.footer {
  &__area {
    overflow: hidden;

    &-2 {
      background-image: url(../../imgs/shape/4.png);
      background-repeat: no-repeat;
      background-position: top right;

      @media #{$lg} {
        background-size: 380px;
      }

      @media #{$md} {
        background-size: 350px;
      }

      @media #{$sm} {
        background-size: 200px;
      }
    }

    &-3 {
      background-color: var(--black-2);
    }
  }

  &__row {
    margin-left: -500px;

    @media #{$sm} {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__top {
    text-align: right;

    img {
      margin-bottom: -380px;

      @media #{$lg} {
        margin-bottom: -300px;
        width: 90%;
      }

      @media #{$md} {
        margin-bottom: -250px;
        width: 100%;
      }

      @media #{$sm} {
        margin-bottom: 0;
        width: 100%;
      }
    }

    &-2 {
      padding-bottom: 70px;

      @media #{$sm} {
        padding-bottom: 50px;
      }
    }

    &-3 {
      border-bottom: 1px solid var(--black-6);
    }

    &-wrapper-3 {
      padding: 0 50px;
      display: grid;
      grid-template-columns: 30% 10% auto;

      @media #{$xl} {
        grid-template-columns: 35% 15% auto;
        gap: 20px;
      }

      @media #{$lg} {
        grid-template-columns: 35% 20% auto;
        gap: 20px;
      }

      @media #{$md} {
        grid-template-columns: 35% 25% auto;
      }

      @media #{$sm} {
        padding: 0 15px;
        grid-template-columns: 1fr;
      }
    }
  }

  &__middle {
    &-2 {
      border-top: 1px solid var(--white-2);
      border-bottom: 1px solid var(--white-2);
      padding: 95px 0;

      @media #{$md} {
        padding: 60px 0;
      }

      @media #{$sm} {
        padding: 50px 0;
      }
    }
  }

  &__location {
    &-2 {
      display: flex;
      gap: 100px;

      @media #{$lg} {
        gap: 50px;
      }

      @media #{$md} {
        flex-direction: column;
        gap: 30px;
      }

      @media #{$sm} {
        flex-direction: column;
        gap: 30px;
      }

      .location {
        h3 {
          font-weight: 400;
          font-size: 22px;
          line-height: 1.5;
          color: var(--black-2);
          padding-bottom: 10px;
        }
      }
    }
  }


  &__btm {

    @media #{$xl} {
      padding-left: 55px;
    }

    @media #{$lg} {
      padding-left: 100px;
    }

    @media #{$sm} {
      background-color: var(--black-2);

      .container {
        padding: 0;
        background-color: var(--black-2);
      }
    }

    &-2 {
      padding: 50px 0;

      @media #{$sm} {
        padding: 30px 0;

        .row {
          flex-direction: column-reverse;
        }
      }
    }

    &-3 {
      padding: 50px;

      @media #{$lg} {
        padding: 40px 15px;
      }

      @media #{$md} {
        padding: 40px 15px;

        .row {
          flex-direction: column-reverse;
        }
      }

      @media #{$sm} {
        padding: 30px 10px;

        .row {
          flex-direction: column-reverse;
        }
      }
    }
  }

  &__inner {
    background-color: var(--black-2);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-left: 350px;
    position: relative;
    z-index: 9;

    @media #{$xxl} {
      padding-left: 512px;
    }

    @media #{$xl} {
      padding-left: 450px;
    }

    @media #{$lg} {
      padding-top: 100px;
      padding-left: 410px;
    }

    @media #{$md} {
      padding-left: 470px;
      padding-top: 80px;
    }

    @media #{$sm} {
      background-color: transparent;
      padding-left: 0;
      padding-top: 60px;
      grid-template-columns: 1fr;
    }
  }

  &__logo {
    margin-bottom: 30px;

    &-3 {
      img {
        margin-bottom: 30px;
      }

      p {
        max-width: 310px;
        color: var(--gray-2);
      }
    }
  }

  &__sub-title {
    max-width: 850px;
    font-weight: 300;
    font-size: 36px;
    line-height: 1.3;
    color: var(--black-3);
    padding-top: 10px;

    @media #{$md} {
      font-size: 30px;
    }

    @media #{$sm} {
      font-size: 24px;
    }
  }


  // Widget
  &__widget {
    grid-column: span 4;
    padding: 140px 0 130px;

    @media #{$lg} {
      grid-column: 1/5;
      padding: 0 0 50px 0;
    }

    @media #{$md} {
      grid-column: 1/6;
      padding: 0 0 50px 0;
    }

    @media #{$sm} {
      padding: 0 0 50px 0;
      grid-column: span 4;
    }

    p {
      max-width: 310px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: var(--gray-2);
      margin-bottom: 30px;
    }

    &-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.3;
      color: var(--white);
      padding-bottom: 20px;
    }

    &-2 {
      grid-column: span 2;
      padding: 140px 0 130px;

      @media #{$lg} {
        grid-column: 6/9;
        padding: 0 0 50px 0;
      }

      @media #{$md} {
        grid-column: 7/-1;
        padding: 0 0 50px 0;
      }

      @media #{$sm} {
        padding: 0 0 50px 0;
        grid-column: span 4;
      }
    }

    &-3 {
      grid-column: span 2;
      padding: 140px 0 130px;
      margin-right: -44px;

      @media #{$lg} {
        grid-column: 9/-1;
        padding: 0 50px 50px 0;
      }

      @media #{$md} {
        grid-column: span 6;
        padding: 50px 60px 0 0;
      }

      @media #{$sm} {
        padding: 0 0 50px 0;
      }
    }

    &-4 {
      grid-column: span 4;
      grid-row: span 2;
      background-color: var(--black-4);
      padding: 135px 70px 30px;
      text-align: center;
      margin-left: 60px;

      @media #{$xxl} {
        padding: 130px 30px 30px;
      }

      @media #{$xl} {
        padding: 130px 40px;
      }

      @media #{$lg} {
        grid-column: 7/-1;
        padding: 50px;
        margin-left: 0;
      }

      @media #{$md} {
        grid-column: span 6;
        padding: 50px 30px;
        margin-left: 0;
      }

      @media #{$sm} {
        padding: 30px;
        margin-left: 0;
      }

      .project-title {
        font-weight: 400;
        font-size: 36px;
        line-height: 1.3;
        color: var(--white);
        padding-bottom: 25px;

        @media #{$xl} {
          font-size: 30px;
        }
      }

      a {
        color: var(--gray-2);
        border-color: var(--secondary);
        margin: 0 auto;
        margin-bottom: 20px;
      }

      .contact-time {
        font-weight: 500;
        font-size: 18px;
        line-height: 1.3;
        color: var(--white);
        padding-bottom: 3px;
      }

      .contact-day {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.3;
        color: var(--gray-2);
      }
    }
  }

  &__social {
    li {
      display: inline-block;
      padding-right: 15px;

      a {
        display: inline-block;
        color: var(--gray-2);
        font-size: 20px;

        &:hover {
          color: var(--white);
        }
      }
    }

    &-3 {
      border-left: 1px solid var(--black-6);
      border-right: 1px solid var(--black-6);

      @media #{$sm} {
        margin-top: 50px;
        border-top: 1px solid var(--black-6);
        border-bottom: 1px solid var(--black-6);
      }

      li {
        display: block;
        border-bottom: 1px solid var(--black-6);

        &:last-child {
          border-bottom: 0;
        }

        a {
          display: block;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5;
          text-align: center;
          text-transform: uppercase;
          color: var(--white);
          padding: 38px 0;
          background-color: transparent;
          transition: all 0.3s;

          @media #{$lg} {
            padding: 30px 0;
          }

          @media #{$md} {
            padding: 30px 0;
          }

          @media #{$sm} {
            padding: 30px 0;
          }

          &:hover {
            color: var(--black-2);
            background-color: var(--white);
          }
        }
      }
    }
  }

  &__link {
    li {
      display: block;
      padding-bottom: 15px;

      a {
        @include footer_text;
        line-height: 18px;
        text-transform: capitalize;

        &:hover {
          color: var(--white);
        }
      }
    }
  }

  &__contact {
    display: block;
    padding-bottom: 10px;
    @include footer_text;

    li {
      padding-bottom: 15px;

      a {
        @include footer_text;

        &.phone {
          color: var(--white);
          @include border_hover;
        }

        &:hover {
          color: var(--white);
        }
      }
    }

    &-3 {
      text-align: right;
      padding-top: 90px;

      @media #{$xl} {
        padding-top: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      @media #{$lg} {
        padding-top: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      @media #{$md} {
        padding-top: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      @media #{$sm} {
        text-align: center;
        margin-bottom: 50px;
        padding-top: 60px;
      }

      a {
        font-weight: 400;
        font-size: 180px;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        color: var(--white);

        @media #{$xxl} {
          font-size: 140px;
        }

        @media #{$xl} {
          font-size: 90px;
        }

        @media #{$lg} {
          font-size: 72px;
        }

        @media #{$md} {
          font-size: 48px;
        }

        @media #{$sm} {
          font-size: 50px;
        }
      }
    }
  }

  &__copyright {
    grid-column: span 4;
    border-top: 1px solid var(--gray-6);
    margin-left: -66px;
    padding: 35px 0 35px 66px;

    @media #{$xxl} {
      margin-left: -35px;
      padding: 35px 0 35px 35px;
    }

    @media #{$xl} {
      margin-left: -54px;
      padding: 35px 0 35px 54px;
    }

    @media #{$lg} {
      border-top: 0;
      margin-left: 0;
      grid-column: 1/5;
      padding: 0;
    }

    @media #{$md} {
      grid-column: 1/-1;
      text-align: center;
      border-top: 0;
    }

    @media #{$sm} {
      grid-column: 1/-1;
      grid-row: 7;
      text-align: center;
      border-top: 0;
      padding-top: 0;
    }

    p {
      @include footer_text;
      max-width: 233px;

      @media #{$md} {
        max-width: 100%;
      }

      @media #{$sm} {
        max-width: 100%;
      }

      a {
        color: var(--white);

        &:hover {
          color: var(--gray);
        }
      }
    }

    &-2 {
      p {
        max-width: 235px;

        @media #{$sm} {
          max-width: 100%;
          padding-top: 50px;
          text-align: center;
        }

        a {
          color: var(--black-2);

          &:hover {
            color: var(--gray-2);
          }
        }
      }
    }

    &-3 {
      p {
        color: var(--white);
        max-width: 235px;

        @media #{$md} {
          max-width: 100%;
          text-align: center;
        }

        @media #{$sm} {
          max-width: 100%;
          text-align: center;
        }

        a {
          color: var(--white);

          &:hover {
            color: var(--gray-2);
          }
        }
      }
    }

    &-8 {
      padding: 20px 100px;

      @media #{$xxl} {
        padding: 20px 50px;
      }

      @media #{$xl} {
        padding: 20px 30px;
      }

      @media #{$lg} {
        padding: 20px 30px;
      }

      @media #{$md} {
        padding: 20px 15px;
      }

      p {
        max-width: 235px;
        line-height: 1.4;

        @media #{$sm} {
          max-width: 100%;
          text-align: center;
        }

        a {
          color: var(--black-2);

          &:hover {
            color: var(--gray-2);
          }
        }
      }
    }

  }

  &__nav {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media #{$sm} {
      justify-content: center;
    }

    &-2 {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media #{$md} {
        justify-content: center;
      }

      @media #{$sm} {
        justify-content: center;
      }
    }
  }

  &__subscribe {
    grid-column: span 4;
    border-top: 1px solid var(--gray-6);
    padding: 35px 0;
    margin-right: -60px;
    padding-right: 30px;

    @media #{$lg} {
      grid-column: 1/7;
      grid-row: 2;
      padding: 0 40px 0 0;
      border-top: 0;
      margin-right: 0;
      display: flex;
      align-items: center;
    }

    @media #{$md} {
      grid-column: span 6;
      grid-row: 3;
      border-top: 0;
      padding: 0 20px 0 0;
      margin-right: 0;
    }

    @media #{$sm} {
      margin-right: 0;
      padding-right: 0;
      border-top: 0;
    }

    form {
      position: relative;
      max-width: 450px;
      width: 100%;
      margin-left: auto;

      @media #{$md} {
        max-width: 100%;
      }

      @media #{$sm} {
        max-width: 100%;
      }
    }

    input {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      color: var(--gray-2);
      background-color: var(--black-4);
      width: 100%;
      border: none;
      border-radius: 0;
      outline: none;
      padding: 21px 50px 21px 15px;

      &::placeholder {
        opacity: 1;
        color: var(--gray-2);
      }
    }

    .subs-btn {
      position: absolute;
      right: 20px;
      top: 16px;
      background-color: transparent;
      border: none;
      color: var(--white);
    }

    &-2 {
      position: relative;
      padding-left: 90px;

      @media #{$md} {
        padding-left: 0;
        display: flex;
        align-items: center;
        height: 100%;
      }

      @media #{$sm} {
        padding-left: 0;
        margin-top: 50px;
      }

      &::before {
        position: absolute;
        content: "";
        width: 1px;
        height: 120px;
        left: -20px;
        top: -15px;
        background-color: var(--white-2);

        @media #{$md} {
          height: 100%;
          top: 0;
        }
      }


      input {
        width: 100%;
        border: none;
        border-bottom: 1px solid var(--black-2);
        font-weight: 300;
        font-size: 24px;
        line-height: 1.5;
        color: var(--black-2);
        padding: 20px 80px 20px 0;
        outline: none;

        @media #{$sm} {
          font-size: 18px;
        }

        &::placeholder {
          color: var(--black-2);
          opacity: 1;
        }
      }

      form {
        position: relative;
      }

      .submit {
        position: absolute;
        right: 0;
        top: 10px;
        background: transparent;
        border: none;

        @media #{$sm} {
          top: 18px;
        }

        img {
          @media #{$sm} {
            width: 45px;
          }
        }
      }
    }
  }
}


footer {

  #btn_wrapper,
  .btn_wrapper {
    margin: 0 auto;
  }
}

.contact_title {
  & span {
    padding-left: 30px;
  }
}

.footer_categories {
  display: flex;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-11);
  padding-bottom: 58px;
  margin: 0 100px;

  @media #{$xxl} {
    margin: 0 50px;
  }

  @media #{$xl} {
    margin: 0 30px;
  }

  @media #{$lg} {
    margin: 0 30px;
  }

  @media #{$md} {
    margin: 0 15px;
  }

  @media #{$sm} {
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
    flex-wrap: wrap;
  }

  &::before {
    content: "";
    position: absolute;
    top: 12%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--gray-11);



    @media #{$sm} {
      top: 14%;
    }
  }

  h4 {
    font-size: 16px;
    color: var(--black-2);
    text-transform: capitalize;
  }
}

.categories_item {
  @media #{$md} {
    width: 100%;
  }

  .cat_title {
    margin-bottom: 74px;

    @media #{$md} {
      margin-bottom: 60px;
    }

    @media #{$sm} {
      margin-bottom: 50px;
      margin-top: 30px;
    }
  }

  input {
    background-color: transparent;
    padding: 5px 80px 20px 0;

    @media #{$md} {
      font-size: 18px;
    }

    @media #{$sm} {
      padding: 15px 80px 15px 0;
    }
  }

  .footer__subscribe-2 {
    padding-left: 0;

    @media #{$sm} {
      margin-top: 20px;
    }

    input {
      @media #{$md} {
        width: inherit;
      }
    }

    &::before {
      display: none;
    }
  }
}

.cat_links {
  li {
    padding-bottom: 12px;

    a {
      display: inline-block;
      padding: 1px 0;
      font-size: 24px;
      line-height: 16px;
      color: var(--black-2);
      text-transform: uppercase;

      @include menu_anim(var(--black-3));

      @media #{$md} {
        font-size: 16px;
      }

      @media #{$sm} {
        font-size: 16px;
      }
    }
  }
}

.categories_item.l_item {
  flex-basis: 36%;

  @media #{$md} {
    .cat_title {
      margin-bottom: 0;
    }
  }

  @media #{$sm} {
    flex-basis: 100%;

    .cat_title {
      margin-top: 50px;
      margin-bottom: 0;
    }
  }
}