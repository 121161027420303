// Colors
:root {
  // new
  --white-6: #d7d7d7;
  --white-7: #B7B7C5;

  --black-14: #323438;
  --black-15: #2C2C2C;

  --lime: #745EFF;
  --lime-2: #5547F5;
  --gray-16: #6B6B6B;
  --gray-17: #F7F7FC;
  --gray-18: #999999;
  --gray-20: #D7D7D7;
  --gray-21: #F2F2F8;
  --gray-22: #FCFCFE;
  --gray-23: #ECECF3;
  --gray-24: #ECECF4;

}

main {
  overflow: hidden;
}

// line css
.line {
  &_4 {
    position: relative;
  }

  &-col {
    &-4 {
      div {
        position: absolute;
        content: "";
        width: 1px;
        height: 100%;
        top: 0;
        left: 0;
        background: var(--white-2);

        &:nth-child(2) {
          left: 33.33%;
        }

        &:nth-child(3) {
          left: 66.66%;
        }

        &:nth-child(4) {
          left: 100%;
        }
      }
    }
  }
}

.portfolio6__line .line-col-4 div {
  height: 1000px;
  top: unset;
  bottom: 0;
}


// button css
.btn {
  &-started {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3;
    padding: 17px 36px 16px;
    color: var(--white);
    border-radius: 5px;
    background: var(--lime);
    display: inline-block;

    i {
      padding-left: 10px;
    }

    &:hover {
      color: var(--white);
      background-color: #FF977B;
    }
  }

  &-view {
    font-weight: 500;
    line-height: 1.3;
    color: var(--black-2);
    position: relative;
    transition: all 0.5s;

    i {
      padding-left: 10px;
    }

    &::after {
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--black-2);
      content: "";
      transition: all 0.5s;
    }

    &:hover::after {
      left: 0;
      width: 1%;
    }

    &:hover {
      color: var(--lime-2);
    }
  }

  &-extra {
    font-size: 16px;
    font-weight: 500;
    color: var(--white);
    line-height: 1.3;
    padding: 9px 14px 11px;
    border-radius: 5px;
    background-color: var(--black-2);
    border: 1px solid var(--black-2);

    &:hover {
      color: var(--black);
      background-color: transparent;
    }
  }

}

// sction title
.title {
  &-wrapper-6 {
    p {
      font-size: 18px;
      line-height: 1.5;
      color: var(--black-3);

      @media #{$sm} {
        font-size: 16px;
      }
    }

  }
}

.sec {
  &-subtile-6 {
    font-size: 16px;
    color: var(--black-2);
    line-height: 1.3;
    padding: 2px 14px 3px;
    font-weight: 400;
    border-radius: 16px;
    border: 1px solid var(--gray-16);
    display: inline-block;
    margin-bottom: 17px;

    @media #{$sm} {
      margin-bottom: 10px;
    }
  }

  &-title-6 {
    font-size: 56px;
    color: var(--black-2);
    font-weight: 500;
    line-height: 65px;
    padding-bottom: 15px;

    @media #{$sm} {
      font-size: 30px;
      padding-bottom: 10px;
    }

    @media #{$md} {
      font-size: 30px;
    }

    @media #{$lg} {
      font-size: 36px;
    }

    @media #{$xl} {
      font-size: 45px;
    }
  }
}


// Hero area
.hero {
  &__area {
    &-6 {
      position: relative;

      .shape-4 {
        position: absolute;
        bottom: -60px;
        left: 180px;
        z-index: 0;

        @media #{$sm} {
          max-width: 260px;
          left: 0;
        }
      }
    }
  }

  &__title {
    &-6 {
      font-size: 100px;
      line-height: 1;
      font-weight: 600;
      color: var(--black-2);
      padding-bottom: 24px;

      @media #{$sm} {
        font-size: 40px;
        padding-bottom: 15px;
      }

      @media #{$md} {
        font-size: 45px;
        padding-bottom: 15px;
      }

      @media #{$lg} {
        font-size: 60px;
      }

      @media #{$xl} {
        font-size: 80px;
      }

      @media #{$xxl} {
        font-size: 80px;
      }
    }
  }

  &__arrow-icon {
    position: absolute;
    right: 0;

    @media #{$sm} {
      top: 83%;
      right: 10%;
      transform: rotate(62deg);
    }

    @media #{$md} {
      right: -15%;
    }

  }

  &__content-6 {
    padding-top: 260px;
    position: relative;

    @media #{$xl} {
      padding-top: 210px;
    }

    @media #{$sm} {
      padding-top: 130px;
    }

    p {
      font-size: 20px;
      padding-bottom: 48px;
      line-height: 1.5;
      font-weight: 400;

      @media #{$sm} {
        font-size: 16px;
        padding-bottom: 40px;
      }

      @media #{$md} {
        br {
          display: none;
        }
      }
    }

  }

  &__right-6 {
    padding-top: 130px;

    @media #{$sm} {
      padding-top: 40px;
    }

    img {
      margin-left: 3px;
      max-width: inherit;

      @media (max-width: 1365px) {
        max-width: 100%;
      }
    }
  }

  &__shape {
    position: absolute;
    top: 22%;
    left: 0;

    @media #{$sm} {
      display: none;
    }

    @media #{$md} {
      top: 13%;
    }

    @media #{$lg} {
      top: 9%;
    }

    @media #{$xxl} {
      top: 10%;
    }

    &-2 {
      position: absolute;
      right: 7%;
      bottom: 30%;

      @media #{$sm} {
        display: none;
      }

      @media #{$md} {
        bottom: 0;
      }

      @media #{$lg} {
        bottom: -8%;
      }

      @media #{$xl} {
        right: 3%;
        bottom: 7%;
      }

      @media #{$xxl} {
        bottom: -7%;
        right: 7%;
      }
    }

    &-3 {
      position: absolute;
      left: 4%;
      bottom: 7%;

      @media #{$sm} {
        display: none;
      }

      @media #{$md} {
        left: 7%;
        bottom: -20%;
      }

      @media #{$lg} {
        bottom: -13%;
      }

      @media #{$xxl} {
        left: 7%;
        bottom: 7%;
      }
    }
  }
}



// Workflow area
.workflow {
  &__area {
    &-4 {
      overflow: hidden;
      text-align: center;

      @media #{$sm} {
        .pb-1230 {
          padding-bottom: 60px;
        }
      }
    }
  }

  &__item-4 {
    padding-top: 64px;

    @media #{$sm} {
      padding-top: 60px;
    }

    img {
      padding-bottom: 30px;
    }

    p {
      font-size: 18px;
      line-height: 1.5;
      color: var(--black-3);
      padding: 0px 55px;

      @media #{$sm} {
        padding: 0px;
        font-size: 16px;
      }

      @media #{$md} {
        padding: 0px;
        font-size: 16px;
      }

      @media #{$lg} {
        padding: 0px;
      }

      @media #{$xl} {
        padding: 0px;
      }
    }
  }

  &__title-4 {
    font-size: 26px;
    font-weight: 500;
    line-height: 1.3;
    color: var(--black);
    padding-bottom: 19px;

    @media #{$sm} {
      font-size: 20px;
      padding-bottom: 15px;
    }
  }
}


// Feature area
.feature {
  &__area {
    &-6 {
      overflow: hidden;
      background-color: var(--gray-17);
    }
  }

  &__content {
    img {
      margin-top: -90px;

      @media #{$sm} {
        margin-top: 0;
        width: 100%;
      }

      @media #{$lg} {
        width: 100%;
      }
    }

    &-right {
      background-color: #1E2024;
      padding: 143px 60px 0px 110px;
      position: relative;

      @media #{$sm} {
        padding: 20px;
        margin-top: 20px;
      }

      @media #{$md} {
        padding: 80px 20px;
      }

      @media #{$lg} {
        padding: 100px 20px 0px 25px;
      }

      p {
        font-size: 18px;
        color: var(--gray-18);
        line-height: 1.5;
        padding-bottom: 25px;
        padding-right: 242px;

        @media #{$sm} {
          padding-right: 0;
          padding-bottom: 15px;
          font-size: 16px;
        }

        @media #{$md} {
          padding-right: 0;
          padding-bottom: 15px;
          font-size: 16px;
        }

        @media #{$lg} {
          padding-right: 140px;
        }

        @media #{$xl} {
          padding-right: 145px;
        }

        @media #{$xxl} {
          padding-right: 225px;
        }

        span {
          color: var(--white);
        }
      }
    }

    &-left {
      padding-top: 143px;

      @media #{$sm} {
        padding-top: 60px;
      }

      @media #{$md} {
        padding-top: 80px;
      }

      @media #{$md} {
        padding-top: 100px;
      }
    }

  }

  &__title-6 {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.3;
    color: var(--white);
    padding-bottom: 22px;
    padding-left: 60px;

    &::before {
      position: absolute;
      top: 13px;
      left: 0;
      width: 50px;
      height: 1px;
      content: "";
      background: var(--white);
    }
  }

  &__content {
    img {
      @media #{$md} {
        width: 100%;
        margin-top: 0;
      }
    }

    &-btm {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 114px;
      height: 100%;
      margin-left: 110px;

      @media #{$sm} {
        gap: 20px;
        margin-left: 0;
        padding: 30px 0px 60px;
      }

      @media #{$md} {
        gap: 20px;
        margin-left: 0;
        padding: 30px 0px 60px;
      }

      @media #{$lg} {
        gap: 20px;
        margin-left: 40px;
      }

      @media #{$xl} {
        gap: 20px;
      }
    }

    &-item {
      img {
        padding-bottom: 40px;

        @media #{$sm} {
          padding-bottom: 20px;
        }

        @media #{$sm} {
          max-width: 100%;
        }
      }

      h5 {
        font-size: 18px;
        color: var(--black-2);
        font-weight: 500;
        line-height: 1.4;
        padding-bottom: 15px;
      }

      p {
        font-weight: 300;
        color: var(--black-3);
      }
    }

  }

  &__img {
    &-1 {
      position: absolute;
      top: 22%;
      right: 10%;

      @media #{$sm} {
        display: none;
      }

      @media #{$md} {
        display: none;
      }

      @media #{$lg} {
        top: 9%;
        right: 4%;
      }
    }

    &-2 {
      margin-left: 100px;

      @media #{$sm} {
        display: none;
      }

      @media #{$md} {
        display: none;
      }
    }

    &-3 {
      position: absolute;
      right: 6%;
      bottom: 5%;

      @media #{$sm} {
        display: none;
      }

      @media #{$md} {
        display: none;
      }
    }
  }
}


// Service area
.service {
  &__title {
    &-wrapper-4 {
      width: 75%;
    }
  }

  &__right-4 {
    p {
      font-size: 18px;
      color: var(--black-3);
      padding-bottom: 30px;
      padding-right: 195px;

      @media #{$sm} {
        font-size: 16px;
        padding-right: 0;
      }

      @media #{$md} {
        padding-right: 0;
        padding-bottom: 15px;
      }

      @media #{$lg} {
        padding-right: 30px;
      }

      span {
        color: var(--black-2);
      }
    }
  }

  &__img-left {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 100%;

    img {
      @media #{$sm} {
        width: 100%;
        margin-bottom: 30px;
      }

      @media #{$md} {
        width: 100%;
      }

      @media #{$lg} {
        width: 100%;
      }
    }
  }

  &__inner-4 {
    margin-top: -20px;

    @media #{$sm} {
      margin-top: 30px;
    }

    @media #{$md} {
      margin-top: 30px;
    }

    @media #{$lg} {
      margin-top: 40px;
    }
  }

  &__item {
    &-4 {
      display: flex;
      align-items: center;
      gap: 62px;
      padding: 12px 0px;
      transition: all 0.5s;
      border-bottom: 1px solid var(--gray-20);

      @media #{$md} {
        gap: 30px;
      }

      @media #{$sm} {
        gap: 20px;
      }

      &:first-child {
        border-top: 1px solid var(--gray-20);
      }

      &:hover {
        .service__item-icon {
          transform: rotate(-45deg);
          opacity: 1;
          visibility: visible;
        }

        .service__item-title {
          color: var(--lime);
        }
      }

    }

    &-number {
      font-size: 18px;
      font-weight: 300;
      color: var(--lime-2);
    }

    &-title {
      font-size: 60px;
      font-weight: 400;
      line-height: 1.4;
      color: var(--black-2);

      @media #{$sm} {
        font-size: 24px;
      }

      @media #{$md} {
        font-size: 24px;
      }

      @media #{$lg} {
        font-size: 36px;
      }

      @media #{$xl} {
        font-size: 40px;
      }

      @media #{$xxl} {
        font-size: 50px;
      }
    }

    &-icon {
      transition: all 0.5s;

      @media #{$sm} {
        opacity: 1;
      }

      i {
        font-size: 23px;
        color: var(--black-2);
      }
    }
  }

}


// Faq area
.faq {
  &__area {
    &-4 {
      background-image: url(../../imgs/bg/4/s1.png);
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      overflow: hidden;
      background-color: #1E2024;

      .sec {
        &-title-6 {
          color: var(--white);
          max-width: 630px;
          line-height: 1.15;
        }

        &-subtile-6 {
          color: var(--white);
        }
      }
    }
  }

  &__list-4 {
    padding-top: 30px;

    .accordion {
      &-body {
        padding-left: 0;
        min-height: 100px;

        p {
          color: var(--gray-2);
          font-size: 18px;

          @media (max-width: 1023px) {
            font-size: 16px;
          }

          span {
            color: var(--white);
          }
        }
      }

      &-item {
        border: none;
        border-radius: 0;
        background-color: transparent;
      }

      &-button {
        background-color: transparent;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.5;
        color: var(--white);
        border-radius: 0;
        padding-top: 10px;
        padding-bottom: 10px;

        &.collapsed::after {
          background-image: none;
          content: "+";
          font-weight: 300;
          font-size: 30px;
          top: 1px;
          position: absolute;
          left: 0;
          transform: rotate(0);
        }


        &:not(.collapsed) {
          box-shadow: none;
          background: transparent;

          &::after {
            background-image: none;
            content: "-";
            font-weight: 300;
            font-size: 30px;
            top: 1px;
            position: absolute;
            left: 0;
            transform: rotate(0);
          }
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  &__sec-title {
    p {
      font-size: 18px;
      color: var(--gray-2);
      padding-bottom: 40px;

      @media #{$sm} {
        font-size: 16px;
      }
    }

    .btn-view {
      color: var(--white);

      &::after {
        background-color: var(--white);
      }
    }

    &-title-4 {
      padding-right: 105px;
    }
  }

  &__img6 {
    &-1 {
      position: absolute;
      bottom: 0;
      left: 35%;
      z-index: 0;
    }

    &-2 {
      position: absolute;
      top: 0;
      left: 51px;
      z-index: 0;

      @media #{$sm} {
        display: none;
      }
    }
  }
}


// Solution area
.solution {
  &__area {
    &-4 {
      overflow: hidden;
      background-color: #1E2024;
      background-image: url(../../imgs/bg/4/solution-bg.png);
      background-position: bottom left;
      background-repeat: no-repeat;
      background-size: contain;
      padding: 179px 0px 170px;
      position: relative;

      @media #{$sm} {
        padding: 60px 0px;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          background-color: var(--black-2);
          opacity: 0.6;
        }
      }

      @media #{$md} {
        padding: 80px 0px;
        background-size: 380px;
      }

      @media #{$lg} {
        padding: 120px 0px;
        background-size: 500px;
      }

      @media #{$xl} {
        padding: 90px 0px;
        background-size: 52%;
      }

      @media #{$xxl} {
        padding: 150px 0px 110px;
        background-size: 55%;
      }
    }
  }

  &__content {
    .sec-title-6 {
      color: var(--white);
    }

    .sec-subtile-6 {
      color: var(--white);
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 270px auto;
    border-top: 1px solid var(--black-14);
    margin-top: 16px;
    padding-top: 24px;

    @media #{$sm} {
      grid-template-columns: 180px auto;
      border-top: 1px solid var(--white-6);
    }

    @media #{$md} {
      grid-template-columns: 1fr;
      border-top: 1px solid var(--white-6);
    }

    @media #{$lg} {
      grid-template-columns: 250px 300px;
      border-top: 1px solid var(--white-6);
    }

    @media #{$xl} {
      grid-template-columns: 250px 300px;
      border-top: 1px solid var(--white-6);
    }

    li {
      font-size: 18px;
      color: var(--white);
      font-weight: 400;
      position: relative;
      padding-left: 15px;
      margin-bottom: 5px;

      @media #{$sm} {
        font-size: 14px;
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        content: "+";
      }
    }
  }
}


// Blog area
.blog {
  &__area {
    &-4 {
      overflow: hidden;

      .sec-title {
        &-wrapper {
          p {
            font-size: 18px;
            color: var(--black-13);
            padding-right: 80px;
            display: inline-block;
            margin-bottom: 55px;

            @media #{$sm} {
              font-size: 16px;
              margin-bottom: 20px;
              padding-right: 0px;
            }

            @media #{$md} {
              font-size: 16px;
              margin-bottom: 20px;
              padding-right: 0px;
            }

            @media #{$lg} {
              padding-right: 30px;
            }
          }
        }
      }
    }
  }

  &__img-4 {
    img {
      width: 100%;
    }
  }

  &__meta-4 {
    display: flex;
    gap: 10px;
    padding-top: 35px;

    a {
      font-size: 14px;
      color: var(--black-2);
      line-height: 1.3;
      padding: 3px 8px;
      background-color: var(--gray-21);
      font-weight: 400;

      &:hover {
        color: var(--lime);
      }
    }

    span {
      font-size: 14px;
      color: var(--black-2);
      line-height: 1.3;
      padding: 3px 8px;
      background-color: var(--gray-21);
      font-weight: 400;
    }
  }

  &__item-4 {
    @media #{$sm} {
      margin-top: 50px;
    }
  }

  &__btn-4 {
    font-weight: 500;
    line-height: 1.3;
    color: var(--black-2);
    position: relative;
    transition: all 0.5s;

    i {
      padding-left: 10px;
    }

    &::after {
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--black-2);
      content: "";
      transition: all 0.5s;
    }

    &:hover::after {
      left: 0;
      width: 1%;
    }

    &:hover {
      color: var(--lime-2);
    }
  }

  &__title-4 {
    font-size: 26px;
    font-weight: 500;
    line-height: 1.4;
    color: var(--black-2);
    transition: all 0.5s;
    padding: 10px 0px 20px;
    display: inline-block;

    @media #{$sm} {
      font-size: 23px;
    }

    @media #{$md} {
      font-size: 16px;
    }

    @media #{$lg} {
      font-size: 20px;
    }

    &:hover {
      color: var(--lime-2);
    }
  }
}

//Cta area
.cta {
  &__area {
    &-4 {
      overflow: hidden;

      .btn-started {
        padding: 16px 28px;
      }
    }
  }

  &__inner-4 {
    background-color: #F1E9E3;
    padding: 66px 60px 0px 60px;
    position: relative;
    border-radius: 10px;

    @media #{$lg} {
      padding: 60px 30px 0;
    }

    @media #{$md} {
      padding: 30px;
    }

    @media #{$sm} {
      padding: 40px 20px;
    }

    img {
      max-width: 100%;

      @media #{$md} {
        transform: translateY(30px);
      }

      @media #{$xxl} {
        max-width: 80%;
      }
    }

    .cta-shape {
      position: absolute;
      bottom: 0;
      right: 460px;
      z-index: 0;

      @media #{$md} {
        bottom: 30px;
        right: 250px;
        max-width: 100px;
      }

      @media #{$sm} {
        bottom: 30px;
        right: 20px;
        max-width: 100px;
      }

      @media #{$lg} {
        max-width: 120px;
        right: 360px;
      }

      &-2 {
        position: absolute;
        bottom: 60px;
        right: 110px;
        z-index: 0;

        @media #{$lg} {
          right: 60px;
        }

        @media #{$md} {
          right: 10px;
          bottom: 120px;
        }
      }
    }
  }

  &__content-4 {
    img {
      @media #{$md} {
        width: 100%;
      }
    }

    height: 100%;

    p {
      font-size: 18px;
      font-weight: 400;
      padding-bottom: 34px;
      margin-top: 15px;
      color: var(--black-2);

      @media #{$sm} {
        font-size: 16px;
        padding-bottom: 20px;
      }

      @media #{$md} {
        font-size: 16px;
        padding-bottom: 20px;
      }
    }
  }

  &__title-4 {
    font-size: 50px;
    font-weight: 500;
    color: var(--black-2);
    line-height: 1.2;
    padding-top: 10px;

    @media #{$sm} {
      font-size: 30px;
    }

    @media #{$md} {
      padding-top: 30px;
      font-size: 28px;
    }

    @media #{$lg} {
      font-size: 36px;
    }

    @media #{$xl} {
      font-size: 40px;
    }
  }
}


//Portfolio area
.portfolio {
  &__area {
    &-4 {
      padding: 0px 45px;
      overflow: hidden;

      @media #{$sm} {
        padding: 0px;
      }

      @media #{$md} {
        padding: 0px 15px;
      }

      @media #{$lg} {
        padding: 0px 31px;
      }

      @media #{$xxl} {
        padding: 0px 60px;
      }

      .cursor {
        position: absolute;
      }

      .cursor.large {
        width: 120px;
        height: 120px;
        font-size: 14px;
      }
    }
  }

  &__wrapper-4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    @media #{$sm} {
      flex-wrap: wrap;
    }

    @media #{$md} {
      flex-wrap: wrap;
    }
  }

  &__item-4 {
    img {
      width: 100%;
    }

    &:hover {
      .portfolio__icon-link {
        transform: rotate(-45deg);
      }
    }
  }

  &__icon-link {
    font-size: 23px;
    transition: all 0.5s;
  }

  &__item-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  &__title-4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
    color: var(--black-2);
    padding-bottom: 5px;
  }

  &__subtitle-4 {
    font-size: 14px;
    color: var(--black-3);
    font-weight: 400;
  }

  &__icon-4 {
    transition: all 0.5s;
    font-size: 23px;
    color: var(--black-2);
  }

  &__sec-title {
    position: absolute;
    bottom: 140px;

    @media #{$sm} {
      position: unset;
      margin-bottom: 30px;
    }

    @media #{$md} {
      position: unset;
    }

    p {
      font-size: 18px;
      color: var(--black-3);
      max-width: 300px;
    }
  }
}


//Testimonial area
.testimonial {
  &__area {
    &-4 {
      overflow: hidden;
      text-align: center;
      padding-top: 120px;
    }
  }

  &__sec-title {
    padding-bottom: 60px;

    p {
      font-size: 18px;
      color: var(--black-3);
      line-height: 1.8;
      max-width: 500px;
      margin: 0 auto;
      text-align: center;

      @media #{$sm} {
        width: 100%;
        font-size: 16px;
      }
    }
  }

  &__slide-4 {
    background-color: var(--gray-22);
    padding: 50px 50px 44px 50px;
    text-align: left;

    @media #{$lg} {
      padding: 35px 30px;
    }

    @media #{$md} {
      padding: 35px 30px 30px 30px;
    }

    @media #{$sm} {
      padding: 35px 30px;
    }
  }

  &__slider-4 {
    padding-bottom: 100px;
    height: 100%;
    width: 98%;
    margin-left: 11px;

    .prev-button:hover,
    .next-button:hover {
      color: var(--lime);
    }

    @media #{$sm} {
      width: 93%;
      padding-bottom: 60px;
    }

    @media #{$md} {
      width: 97%;
      padding-bottom: 80px;
    }

    @media #{$lg} {
      padding-bottom: 100px;
    }

    @media #{$xl} {
      padding-bottom: 110px;
    }
  }

  &__img-4 {
    margin-bottom: 42px;
    border-radius: 100px;
    width: 85px;
    height: auto;
  }

  &__feedback-4 {
    font-size: 14px;
    font-weight: 400;
    color: var(--black-2);
    border-radius: 5px;
    padding: 3px 10px;
    margin-bottom: 18px;
    display: inline-block;
    line-height: 1.3;
    background-color: var(--gray-23);
  }

  &__info-4 {
    p {
      color: var(--black-3);
      font-weight: 400;
      line-height: 1.8;
      font-size: 18px;
      padding-bottom: 19px;
      text-align: left;
    }
  }

  &__name-4 {
    font-size: 18px;
    font-weight: 500;
    color: var(--black);
    line-height: 1.3;

  }

  &__role-4 {
    font-size: 14px;
    color: var(--black-3);
    font-weight: 400;

  }

  &__btn-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 160px;
    padding-top: 30px;
    position: relative;
    z-index: 999;

    @media #{$sm} {
      padding-top: 35px;
    }

    i {
      font-size: 18px;
    }
  }

  &__pagination-4 {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    bottom: 1px;
    z-index: -1;
  }


}

//Footer area
.footer {
  &__area {
    &-6 {
      overflow: hidden;
      background-color: var(--gray-17);
    }
  }

  &__top-6 {
    display: flex;
    justify-content: space-between;

    @media #{$sm} {
      flex-wrap: wrap;
      padding-bottom: 25px;
    }

    @media #{$md} {
      flex-wrap: wrap;
      padding-bottom: 5px;
    }
  }

  &__social-6 {
    display: flex;
    gap: 20px;

    li {
      a {
        color: var(--gray-2);
        font-size: 18px;
        transition: all 0.3s;

        &:hover {
          color: var(--black-2);
        }
      }
    }
  }

  &__info-6 {
    li {
      color: var(--black-13);
      font-size: 16px;
      margin-bottom: 15px;

      a {
        color: var(--black-13);
        transition: all 0.5s;

        &:hover {
          color: var(--black-2);
        }

        &.phone {
          color: var(--black-2);
          @include border_hover;

          &::after {
            background-color: var(--black-2);
          }
        }
      }
    }
  }

  &__link-6 {
    li {
      margin-bottom: 8px;

      a {
        color: var(--black-13);
        transition: all 0.5s;

        &:hover {
          color: var(--black-2);
        }
      }
    }
  }

  &__item {
    &-title {
      font-size: 20px;
      color: var(--black-2);
      font-weight: 600;
      line-height: 1.3;
      padding-bottom: 20px;
      text-transform: capitalize;
    }

    &-6 {
      @media #{$sm} {
        margin-bottom: 40px;
      }

      @media #{$md} {
        margin-bottom: 50px;
      }

      &:nth-child(1) {
        width: 25%;

        @media #{$sm} {
          width: 100%;
        }

        @media #{$md} {
          width: 50%;
          padding-right: 30px;
        }
      }

      &:nth-child(2) {
        width: 14%;

        @media #{$sm} {
          width: 100%;
        }

        @media #{$md} {
          width: 50%;
        }
      }

      &:nth-child(3) {
        width: 20%;

        @media #{$sm} {
          width: 100%;
        }

        @media #{$md} {
          width: 50%;
          padding-right: 30px;
        }
      }

      &:nth-child(4) {
        width: 28%;

        @media #{$sm} {
          width: 100%;
        }

        @media #{$md} {
          width: 50%;
        }

        @media #{$lg} {
          width: 33%;
        }
      }

      p {
        padding-bottom: 20px;
        color: var(--gray-2);

        @media #{$lg} {
          br {
            display: none;
          }
        }

        @media #{$xl} {
          br {
            display: none;
          }
        }
      }

      &-social {
        a {
          &:hover {
            color: var(--black-2);
          }
        }
      }
    }
  }

  &__btm-6 {
    border-top: 1px solid var(--gray-24);
    padding-top: 40px;
    padding-bottom: 43px;
  }

  &__newsletter-6 {
    background-color: var(--gray-24);
    display: flex;
    border-radius: 5px;
    position: relative;

    input {
      width: 100%;
      background-color: transparent;
      border: 0;
      outline: 0;
      padding: 16px 50px 16px 20px;

      &::placeholder {
        font-size: 14px;
        color: var(--white-7);
        font-weight: 400;
      }
    }

    button {
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }

  &__copyright-6 {
    color: var(--black-13);

    p {
      a {
        color: var(--black-2);

        &:hover {
          color: var(--lime);
        }
      }
    }
  }

  &__chekbox {
    display: flex;
    gap: 15px;
    margin-top: 18px;

    input {
      width: 30px;
      height: 30px;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
      color: var(--black-3);
    }
  }
}



// Animation css

@keyframes leftmove {
  0% {
    margin-left: 0;
  }

  50% {
    margin-left: -20px;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes rightmove {
  0% {
    margin-right: 0;
  }

  50% {
    margin-right: 30px;
  }

  100% {
    margin-right: 0;
  }
}

@keyframes move {
  0% {
    margin-bottom: 0;
  }

  50% {
    margin-bottom: -20px;
  }

  100% {
    margin-bottom: 0;
  }
}

@keyframes move {
  0% {
    margin-top: 0;
  }

  50% {
    margin-top: -20px;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes rotate-icon {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.hero {
  &__shape {
    animation-name: leftmove;
    animation-delay: 4s;
    animation: leftmove infinite 4s;
  }

  &__arrow-icon {
    animation-name: rightmove;
    animation-delay: 3s;
    animation: rightmove infinite 3s;
  }

  &__shape-2 {
    animation-name: move;
    animation-delay: 3s;
    animation: move infinite 4s;
  }

  &__shape-3 {
    animation: rotate-icon 5s linear 0s infinite normal;
  }

}

.faq__img6-1 {
  animation-name: move;
  animation-delay: 3s;
  animation: move infinite 4s;
}

.faq__img6-2 {
  animation-name: move;
  animation-delay: 3s;
  animation: move infinite 4s;

}



// Dark Version
.dark {
  .line-col-4 div {
    background: var(--bg-line);
  }

  .btn-view {
    color: var(--white);

    &::after {
      background-color: var(--white);
    }
  }

  .sec-subtile-6,
  .sec-title-6,
  .workflow__title-4,
  .feature__content-item h5,
  .service__item-title,
  .service__right-4 p span,
  .portfolio__title-4,
  .portfolio__icon-4,
  .testimonial__name-4,
  .footer__item-title,
  .footer__newsletter-6 button {
    color: var(--white);
  }

  .title-wrapper-6 p,
  .workflow__item-4 p,
  .feature__content-item p,
  .service__right-4 p,
  .portfolio__subtitle-4,
  .portfolio__sec-title p,
  .testimonial__sec-title p,
  .blog__area-4 .sec-title-wrapper p,
  .testimonial__info-4 p,
  .testimonial__role-4,
  .footer__info-6 li,
  .footer__chekbox label {
    color: var(--gray-2);
  }


  .hero {
    &__area-6 .shape-4 {
      filter: invert(0.93);
    }

    &__title-6 {
      color: var(--white);
    }
  }

  .feature__area-6 {
    background-color: var(--d-black-2);
  }

  .testimonial__slider-4 {
    color: var(--white);
  }

  .solution__area-4 {
    overflow: hidden;
    background-color: var(--d-black-2);
  }

  .blog__title-4 {
    color: var(--white);

    &:hover {
      color: var(--lime);
    }
  }

  .blog__btn-4 {
    color: var(--white);

    &:hover {
      color: var(--lime);
    }

    &::after {
      background-color: var(--white);
    }
  }

  .testimonial__slide-4 {
    background: var(--d-black-2);
  }

  .testimonial__feedback-4 {
    color: var(--gray-2);
    background-color: var(--black-2);
  }

  .blog__meta-4 span {
    color: var(--white-6);
    background-color: var(--bg-line);
  }

  .blog__meta-4 a {
    color: var(--white-6);
    background-color: var(--bg-line);

    &:hover {
      color: var(--lime);
    }
  }

  .footer__area-6 {
    background-color: var(--d-black-2);
  }

  .footer__link-6 li a {
    color: var(--gray-2);

    &:hover {
      color: var(--white);
    }
  }

  .footer__info-6 li a {
    color: var(--gray-2);

    &:hover {
      color: var(--white);
    }
  }

  .footer__info-6 li a.phone {
    color: var(--white);

    &::after {
      background-color: var(--white);
    }
  }

  .footer__newsletter-6 input {
    color: var(--white-2);
    background-color: var(--black-2);
  }

  .footer__btm-6 {
    border-color: var(--bg-line);
  }

  .footer__copyright-6 p a {
    color: var(--white);

    &:hover {
      color: var(--lime);
    }
  }

  .service__item-4 {
    border-color: var(--bg-line);

    &:first-child {
      border-color: var(--bg-line);
    }
  }

  .service__item-icon i {
    color: var(--white);
  }

}