.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* padding: 50px; */
  z-index: 9999;
  border-radius: 20px;
  width: 50%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  box-shadow: "box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;";
}

.popup-content {
  /* Add your additional styles for the popup content */
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  /* Add additional styles for the close button */
}

.close-pop {
  height: 30px;
  width: 30px;
  padding-top: 10px;
  padding-right: 10px;
}

.contact__form-pop {
  padding: 50px;
  padding-top: 80px;
  padding-right: 50px;
  padding-left: 130px;
}

.dis-contents {
  display: flex;
}

.pop-img-dholera {
  height: 100%;
  width: 500px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.contact__form-pop input,
.contact__form-pop textarea {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black-2);
  border: none;
  border-bottom: 1px solid var(--gray);
  padding: 15px 0;
  transition: all 0.3s;
  outline: 0;
  position: relative;
  z-index: 1;
  background: transparent;
  margin-bottom: 15px !important;
}

.contact__form-pop #btn_wrapper,
.contact__form-pop .btn_wrapper {
  margin-top: -40px !important;
  margin-bottom: -25px !important;
}

.pop-background:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(0 0 0 / 84%);
  z-index: 10;
}

.pop-background:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: -1;
  display: block;
}

@media (max-width : 1810px) and (min-width : 1439px ){
  .contact__form-pop {
    padding: 50px;
    padding-top: 80px;
    padding-right: 50px;
    padding-left: 130px;
  }
  .pop-img-dholera {
    height: 100%;
    width: 400px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}  
}

@media (max-width: 1440px) and (min-width: 1400px) {
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border-radius: 20px;
    width: 55%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    box-shadow: "box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;";
  }

  .contact__form-pop {
    padding-top: 120px;
    padding-right: 32px;
    padding-left: 93px;
}

  .pop-img-dholera {
    height: 100%;
    width: 400px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }

  .contact__form-pop #btn_wrapper,
  .contact__form-pop .btn_wrapper {
    margin-top: -80px !important;
    margin-bottom: -25px !important;
  }

  .btn_wrapper {
    margin-top: 20px;
  }
}
@media (max-width: 1399px) and (min-width: 1200px) {
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border-radius: 20px;
    width: 55%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    box-shadow: "box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;";
  }

  .contact__form-pop {
    padding-top: 80px;
    padding-right: 32px;
    padding-left: 93px;
}

  .pop-img-dholera {
    height: 100%;
    width: 361px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }

  .contact__form-pop .btn_wrapper {
    margin-top: -40px !important;
    margin-bottom: -25px !important;
}

  .btn_wrapper {
    margin-top: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border-radius: 20px;
    width: 56%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    box-shadow: "box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;";
  }

  .contact__form-pop {
    padding-top: 60px;
    padding-right: 40px;
    padding-left: 30px;
  }

  .pop-img-dholera {
    height: 100%;
    width: 303px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }
}
@media (min-width: 900px) and (max-width: 1023px) {
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border-radius: 20px;
    width: 56%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    box-shadow: "box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;";
  }

  .contact__form-pop {
    padding-top: 60px;
    padding-right: 40px;
    padding-left: 30px;
  }

  .pop-img-dholera {
    height: 100%;
    width: 258px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }
}
@media (min-width: 768px) and (max-width: 899px) {
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border-radius: 20px;
    width: 56%;
    height: 70%;
    display: block;
    justify-content: space-between;
    box-shadow: "box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;";
  }

  .contact__form-pop {
    padding-top: 75px;
    padding-right: 40px;
    padding-left: 30px;
  }

  .pop-img-dholera {
    display: none;
  }
  .popup-content {
    width: 100%;
  }
  .plot-txt-enquire {
    font-size: 30px !important;
  }
  .btn-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px !important;
    height: 100px !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 1.3;
    text-transform: capitalize;
    color: var(--black-3);
    border: 1px solid var(--gray);
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
  }
  .contact__form-pop .btn_wrapper {
    margin-top: -13px !important;
    margin-bottom: -25px !important;
  }
}
@media (min-width: 426px) and (max-width: 767px) {
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border-radius: 20px;
    width: 56%;
    height: 70%;
    display: block;
    justify-content: space-between;
    box-shadow: "box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;";
  }

  .contact__form-pop {
    padding-top: 30px;
    padding-right: 40px;
    padding-left: 30px;
  }

  .pop-img-dholera {
    display: none;
  }
  .popup-content {
    width: 100%;
  }
  .plot-txt-enquire {
    font-size: 30px !important;
  }
  .btn-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px !important;
    height: 130px !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 1.3;
    text-transform: capitalize;
    color: var(--black-3);
    border: 1px solid var(--gray);
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
    z-index: 9999;

  }
  .contact__form-pop .btn_wrapper {
    margin-top: -13px !important;
    margin-bottom: -25px !important;
  }
}
@media (min-width: 320px) and (max-width: 425px) {
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border-radius: 20px;
    width: 85%;
    height: 70%;
    display: block;
    justify-content: space-between;
    box-shadow: "box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;";
  }

  .contact__form-pop {
    padding-top: 30px;
    padding-right: 40px;
    padding-left: 30px;
  }

  .pop-img-dholera {
    display: none;
  }
  .popup-content {
    width: 100%;
  }
  .plot-txt-enquire {
    font-size: 30px !important;
  }
  .btn-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px !important;
    height: 130px !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 1.3;
    text-transform: capitalize;
    color: var(--black-3);
    border: 1px solid var(--gray);
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
    z-index: 9999;

  }
  .contact__form-pop .btn_wrapper {
    margin-top: -13px !important;
    margin-bottom: -25px !important;
  }
}
