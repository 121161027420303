#open_offcanvas{
  display: none;
}
div.cs-fooer-2 {
  position: fixed;
  display: none;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  z-index: 9999;
}
.cs-bottom_footer {
  border-top: 0px solid #4d4d4d !important;
}
.cs-bottom_footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 0px solid #4d4d4d !important;
}
.cs-bottom_footer {
  justify-content: center;
}
.footer-button {
  padding: 8px 25px;
  font-family: poppins, Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: .6px;
  width: 230px;
  border-radius: 50px;
  height: 50px;
  color: #ffffff;
  background-color: #ad042b;
  border-width: 0;
  border-color: #ad042b;
  box-shadow: 0 10px 24px 0 rgba(255,235.99999999999997,228,.5);
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  z-index: 999;
  display: block;
  position: relative;
  text-align: center;
  margin-left: 0 !important;
  display: block;
}
.main-menu-3 > li > a {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--black-2);
  padding: 27px 0;
  outline: none;
  text-transform: capitalize;
  transform: translateY(var(--y)) translateZ(0);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}
@media (max-width: 1200px) {
  .header__inner-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main-menu-3 > li.has-megamenu {
    position: static;
    padding-top: 0;
  }
  div.cs-fooer-2 {
    position: fixed;
    display: none;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
    z-index: 9999;
  }
  .whatsapp-stick{
    bottom: 110px;
  }
}
.btn-buynow{
  display: inline-block;
    background: #ad042b;
    border: 1px solid #ad042b;
    border-radius: 58px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: #121212;
    padding: 10px 45px;
    transition: all 0.5s;
    text-transform: uppercase;
    white-space: nowrap;
    margin-right: 25px;
    color: white;

}

@media (min-width: 320px) and (max-width: 767px){
  .btn-buynow{
    display: none;
  }
  .dainty-header-logo {
    height: 50px;
}
#open_offcanvas{
  display: block;
}

div.cs-fooer-2 {
  position: fixed;
  display: flex;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  z-index: 9999;
}
.cs-bottom_footer {
  border-top: 0px solid #4d4d4d !important;
}
.cs-bottom_footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 0px solid #4d4d4d !important;
}
.cs-bottom_footer {
  justify-content: center;
}
.footer-button {
  padding: 8px 25px;
  font-family: poppins, Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: .6px;
  width: 230px;
  border-radius: 50px;
  height: 50px;
  color: #ffffff;
  background-color: #ad042b;
  border-width: 0;
  border-color: #ad042b;
  box-shadow: 0 10px 24px 0 rgba(255,235.99999999999997,228,.5);
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  z-index: 999;
  display: block;
  position: relative;
  text-align: center;
  margin-left: 0 !important;
  display: block;
}
.btn-scroll {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  bottom: 110px;
  z-index: 9991;
  border-radius: 100px;
  color: var(--white);
  transition: all 0.3s;
  /* display: none; */
  /* mix-blend-mode: exclusion; */
  background-color: black;
}
.footer__inner {
  padding-bottom: 50px;
}
}

@media (min-width: 768px) and (max-width: 991px) {
  .btn-buynow {
    display: none;
}
#open_offcanvas{
  display: block;
}

div.cs-fooer-2 {
  position: fixed;
  display: flex;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  z-index: 9999;
}
.cs-bottom_footer {
  border-top: 0px solid #4d4d4d !important;
}
.cs-bottom_footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 0px solid #4d4d4d !important;
}
.cs-bottom_footer {
  justify-content: center;
}
.footer-button {
  padding: 8px 25px;
  font-family: poppins, Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: .6px;
  width: 230px;
  border-radius: 50px;
  height: 50px;
  color: #ffffff;
  background-color: #ad042b;
  border-width: 0;
  border-color: #ad042b;
  box-shadow: 0 10px 24px 0 rgba(255,235.99999999999997,228,.5);
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  z-index: 999;
  display: block;
  position: relative;
  text-align: center;
  margin-left: 0 !important;
  display: block;
}
.btn-scroll {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  bottom: 110px;
  z-index: 9991;
  border-radius: 100px;
  color: var(--white);
  transition: all 0.3s;
  /* display: none; */
  /* mix-blend-mode: exclusion; */
  background-color: black;
}
.footer__inner {
  padding-bottom: 90px;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-buynow {
    display: none;
}
#open_offcanvas{
  display: block;
}
div.cs-fooer-2 {
  position: fixed;
  display: flex;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  z-index: 9999;
}
.cs-bottom_footer {
  border-top: 0px solid #4d4d4d !important;
}
.cs-bottom_footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 0px solid #4d4d4d !important;
}
.cs-bottom_footer {
  justify-content: center;
}
.footer-button {
  padding: 8px 25px;
  font-family: poppins, Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: .6px;
  width: 230px;
  border-radius: 50px;
  height: 50px;
  color: #ffffff;
  background-color: #ad042b;
  border-width: 0;
  border-color: #ad042b;
  box-shadow: 0 10px 24px 0 rgba(255,235.99999999999997,228,.5);
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  z-index: 999;
  display: block;
  position: relative;
  text-align: center;
  margin-left: 0 !important;
  display: block;
}
.btn-scroll {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  bottom: 110px;
  z-index: 9991;
  border-radius: 100px;
  color: var(--white);
  transition: all 0.3s;
  /* display: none; */
  /* mix-blend-mode: exclusion; */
  background-color: black;
}
.footer__inner {
  padding-left: 410px;
  padding-bottom: 90px;
}
}
@media  (min-width: 1300px) and (max-width: 1440px) {
  .btn-buynow {
    display: inline-block;
    background: #ad042b;
    border: 1px solid #ad042b;
    border-radius: 58px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.2;
    color: #121212;
    padding: 10px 28px;
    transition: all 0.5s;
    text-transform: uppercase;
    white-space: nowrap;
    margin-right: 25px;
    color: white;
  display: block;

}
.main-menu-3 > li {
  display: inline-block;
  padding: 0 25px;
  margin: 0;
}
.col-xxl-6.col-xl-6.col-lg-6.mt-70.dholera-top {
  vertical-align: middle;
}
#open_offcanvas{
  display: none;
}
div.cs-fooer-2 {
  position: fixed;
  display: none;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  z-index: 9999;
}
}