body.thankyou-active {
  overflow: hidden;
}
.wc-btn-primary {
  cursor: pointer;
}
.header__area-3 {
  display: none;
}
.btn-scroll{
  display: none;
}
div.cs-fooer-2{
  display: none ;
}
body:not(.thankyou-active) .header__area-3 {
  display: block;
}
body:not(.thankyou-active) .btn-scroll{
  display: block;
}
body:not(.thankyou-active) div.cs-fooer-2{
  display: block;
}
.pb-30{
    padding-bottom: 30px;
}
.thankyou-container {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(249 249 249 / 100%); /* Add a semi-transparent background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Set a higher z-index to ensure it appears on top of other elements */
    background: url('../dainty-grp/thank-you.webp');
  }
  
  .thankyou-content {
    background-color: #fff; /* Set the background color for your content */
    padding: 20px;
    border-radius: 10px;
    max-width: 400px; /* Set a maximum width for your content */
    text-align: center;
  }
  
  .thanks-txt {
    font-size: 35px;
    font-weight: bold;
    color: #ad042b;
  }
  
  .btn_wrapper {
    margin-top: 20px;
  }
  .circle-line-thanks{
    position: absolute;
    z-index: 2;
    top: 170px;
    left: 0;
  }

  @media (min-width: 320px) and (max-width: 500px){
    .thanks-txt {
      font-size: 30px;
      font-weight: bold;
      color: #ad042b;
      padding: 15px;
  }
  }