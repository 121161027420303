/*----------------------------------------*/
/*  10. MODAL CSS START
/*----------------------------------------*/


.modal {

  &__testimonial {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    z-index: 9991;
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;

    &-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--white);
      border-radius: 5px;
      width: 760px;
      height: 500px;

      @media #{$md} {
        width: 700px;
        height: 480px;
      }

      @media #{$sm} {
        width: 320px;
        height: 300px;
      }


      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__close {
    background: var(--secondary);
    color: var(--white);
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 100%;
    font-size: 18px;
    position: absolute;
    right: -15px;
    top: -15px;
    cursor: pointer;
    transition: all 0.3s;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$sm} {
      right: -5px;
      top: -5px;
    }

    &:hover {
      color: var(--primary);
    }

    &-2 {
      background-color: transparent;
      color: var(--black-3);
      width: 30px;
      height: 30px;
      border: none;
      font-size: 24px;
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
      transition: all 0.3s;

      @media #{$sm} {
        right: 20px;
        top: 15px;
      }

      &:hover {
        color: var(--black-2);
      }
    }
  }

  &__application {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    z-index: 9991;
  }

  &__apply {
    padding: 100px 50px;
    max-width: 1130px;
    height: 96vh;
    margin: 0 auto;
    margin-top: 2vh;
    overflow-y: auto;
    position: relative;
    background-color: var(--white);

    @media #{$lg} {
      max-width: 960px;
    }

    @media #{$md} {
      max-width: 720px;
    }

    @media #{$sm} {
      padding: 50px 20px;
      margin-top: 0;
      height: 100vh;
    }

    .wc-btn-primary {
      width: 120px;
      height: 120px;
    }

    .form-top {
      text-align: center;
      padding-bottom: 50px;

      img {
        margin-bottom: 40px;
      }

      p {
        font-size: 18px;
        color: var(--black-2);
        border: 1px solid var(--black-3);
        border-radius: 30px;
        display: inline-block;
        padding: 5px 35px;
        margin-top: 20px;
      }
    }

    .input-apply {
      display: grid;
      grid-template-columns: 1fr 2.2fr;
      grid-gap: 90px;
      margin-bottom: 20px;

      @media #{$xl} {
        grid-template-columns: 1fr 2fr;
        grid-gap: 30px;
      }

      @media #{$lg} {
        grid-template-columns: 1fr 2fr;
        grid-gap: 30px;
      }

      @media #{$md} {
        grid-template-columns: 1fr;
        grid-gap: 10px;
        margin-bottom: 30px;
      }

      @media #{$sm} {
        grid-template-columns: 1fr;
        grid-gap: 10px;
        margin-bottom: 30px;
      }

      &-2 {
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-gap: 50px;
        margin-bottom: 20px;

        @media #{$xl} {
          grid-template-columns: 1fr 2fr;
          grid-gap: 30px;
        }

        @media #{$lg} {
          grid-template-columns: 1fr 2fr;
          grid-gap: 30px;
        }

        @media #{$md} {
          grid-template-columns: 1fr;
          grid-gap: 10px;
          margin-bottom: 30px;
        }

        @media #{$sm} {
          grid-template-columns: 1fr;
          grid-gap: 10px;
          margin-bottom: 30px;
        }

        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 1.5;
          color: var(--black);
        }

        input {
          width: 100%;
          border: 1px solid var(--white-4);
          outline: none;
          padding: 15px;
          transition: all 0.3s;

          &:focus {
            border: 1px solid var(--black-3);
          }
        }
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.5;
        color: var(--black);
      }

      textarea {
        width: 100%;
        height: 125px;
        border: 1px solid var(--white-4);
        outline: none;
        padding: 15px;
        transition: all 0.3s;

        @media #{$md} {
          height: 90px;
        }

        @media #{$sm} {
          height: 90px;
        }

        &:focus {
          border: 1px solid var(--black-3);
        }
      }
    }

    .form-btn {
      button {
        margin-left: auto;
        margin-top: 30px;
        background-color: transparent;
        z-index: 1;
      }

      &-2 {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        margin-top: 20px;

        button {
          background-color: transparent;
          z-index: 1;
        }
      }
    }
  }


  &-show {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  }
}