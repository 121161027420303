.header__area-9 {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;
}

.hero_layer_9 {
  position: relative;
}

#main {
  display: flex;
}

.content {
  color: #fff;
  position: absolute;
  bottom: 120px;
  text-align: center;
  left: 50px;
  z-index: 99;
  font-size: 0.8em;
  text-transform: uppercase;

  p {
    color: var(--white);
  }
}

.hero9_title {
  font-size: 80px;
  font-weight: 500;
  margin-bottom: 30px;

  span {
    font-family: $font_newyork;
    position: relative;
    padding-left: 30px;

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      left: -20%;
      height: 2px;
      width: 70px;
      background-color: var(--white);
    }
  }
}

.content a {
  color: rgba(227, 227, 227, 0.78);
  text-decoration: none;
  border-bottom: 1px solid currentColor;
}

.content a:hover {
  padding-bottom: 1px;
}

.buttons {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}

.buttons button {
  border: none;
  background-size: contain;
  background: url(https://raw.githubusercontent.com/Rajacharles/GSAP-Fullscreen-Slider/master/images/down-arrow.png) no-repeat;
  background-position: center;
  width: 10px;
  height: 30px;
  display: block;
  margin: 20px 0;
  padding: 0 15px;
  transition-duration: 0.6s;
}

.buttons button.next {
  transform: scaleY(-1);
}

.buttons button.next:active {
  transform: scaleY(-1) translateY(8px);
}

.buttons button.prev:active {
  transform: translateY(8px);
}

#main .part {
  flex: 1;
}

#main .part .section {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

#main .part .section img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: var(--x);
  pointer-events: none;
}

.section {
  display: block;
  width: 100%;
  height: 100%;
}


.part:first-child h2 {
  display: block;
  top: 45% !important;
  left: 49% !important;
  position: absolute;
  z-index: 9999999999999 !important;
  width: 500px;
  background: transparent;
  height: 400px;
}


#header_10 {
  position: static;
  background: transparent;
}

#home10_menu {
  li {
    >a {
      color: var(--black);
    }
  }
}

.hero {
  &__area {
    &-10 {
      .swiper.hero10_activition {
        padding-bottom: 110px;
      }

      .swiper-button-prev.hero_prev {
        color: var(--black-3);
        text-transform: uppercase;
        bottom: 40px;
        position: absolute;
        top: unset;
        width: 85px;
        left: 0;
        justify-content: space-between;
        transition: all 0.3s;

        @media (max-width: 1365px) {
          img {
            margin-left: 0;
          }
        }

        &:hover {
          color: var(--black-2);

          img {
            margin-left: 10px;

            @media (max-width: 1365px) {
              margin-left: 0;
            }
          }
        }

        @media #{$xxl} {
          bottom: 25px;
        }

        @media #{$sm} {
          width: 75px;
        }

        img {
          transition: all 0.3s;
          max-width: 100%;
        }
      }

      .swiper-button-next.hero_next {
        text-transform: uppercase;
        bottom: 40px;
        position: absolute;
        top: unset;
        right: 0;
        width: 85px;
        color: var(--black-3);
        justify-content: space-between;
        transition: all 0.3s;

        @media (max-width: 1365px) {
          img {
            margin-right: 0;
          }
        }

        &:hover {
          color: var(--black-2);

          img {
            margin-right: 10px;

            @media (max-width: 1365px) {
              margin-right: 0;
            }
          }
        }

        @media #{$xxl} {
          bottom: 25px;
        }

        @media #{$sm} {
          width: 75px;
        }

        img {
          transition: all 0.3s;
          max-width: 100%;
        }
      }

      .swiper-button-prev:after,
      .swiper-rtl .swiper-button-next:after {
        display: none;
      }

      .swiper-button-next:after,
      .swiper-rtl .swiper-button-prev:after {
        display: none;
      }

      .increase-dash {
        font-size: 6px;
        width: 90px;
        background: var(--black);
        height: 1px;
        color: #1C1D20;

        @media #{$sm} {
          width: 50px;
        }
      }

      .hero10_activition {
        position: relative;

        .swiper-pagination-current {
          position: relative;
          display: inline-block;
          width: 22px;

          @media #{$sm} {
            margin-left: 22px;
          }

          &::before {
            content: "0";
            position: absolute;
            left: -5px;
          }
        }

        .swiper-pagination-total {
          position: relative;
          display: inline-block;
          width: 48px;

          &::before {
            content: "0";
            position: absolute;
            left: 8px;
          }
        }

        .swiper-pagination {
          color: var(--black);
          width: 190px;
          left: 50%;
          bottom: 50px;
          transform: translateX(-50%);

          @media #{$xxl} {
            bottom: 30px;
          }

          @media #{$sm} {
            width: 160px;
          }
        }
      }
    }
  }
}



// Home 10
.hero10 {
  position: relative;
  overflow: hidden;
  z-index: 2;
  transition: all 0.5s;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
    background: rgba(0, 0, 0, 0.3);
  }

  &:hover {
    &::after {
      opacity: 1;
      visibility: visible;
    }

    .hero10 {
      &__content {
        i {
          opacity: 1;
          transform: translateY(-50px) rotate(-45deg);
        }

        &__title {
          opacity: 1;
          transform: translateY(-50px);
        }

        &__name {
          opacity: 1;
          transform: translateY(-50px);
        }
      }

      &__thumb {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  &__thumb {
    @media #{$xl} {
      height: 470px;
    }

    @media #{$lg} {
      height: 470px;
    }

    @media #{$md} {
      height: 470px;
    }

    @media #{$sm} {
      height: 70vh;
    }

    img {
      width: 100%;
      transition: all 5s;
      transform: scale(1);
    }
  }

  &__content {
    color: var(--white);
    position: absolute;
    left: 40px;
    z-index: 9;
    bottom: 0;
    transition: 0.5s;


    i {
      color: var(--white);
      font-size: 24px;
      margin-bottom: 35px;
      transform: rotate(0deg);
      opacity: 0;
      transition: all 0.5s;

      @media (max-width: 1365px) {
        opacity: 1;
        transform: rotate(-45deg);
      }

      @media #{$sm} {
        margin-bottom: 20px;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 45px;
      line-height: 1;
      text-transform: uppercase;
      color: var(--white);
      opacity: 0;
      transition: all 0.5s;
      transition-delay: 0.1s;
      transform: translateY(-30px);

      @media (max-width: 1365px) {
        font-size: 36px;
        opacity: 1;
      }

      @media #{$sm} {
        font-size: 30px;
      }

      span {
        display: block;
        font-weight: 400;
        font-family: $font_newyork;
      }
    }

    &__name {
      color: var(--white);
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 7px;
      display: inline-block;
      text-transform: uppercase;
      opacity: 0;
      transform: translateY(-30px);
      transition: all 0.5s;

      @media (max-width: 1365px) {
        opacity: 1;
      }
    }

    &__date {
      font-size: 14px;
      font-weight: 500;
      color: var(--white);
    }
  }
}

.showcase4 {
  margin-bottom: 90px;

  @media #{$lg} {
    margin-bottom: 50px;
  }

  @media #{$md} {
    margin-bottom: 50px;
  }

  @media #{$sm} {
    margin-bottom: 40px;
  }

  .showcase5_content {
    position: absolute;
    bottom: 50px;
    left: 50px;
    color: var(--white);
  }

  &__thumb {
    position: relative;
    display: inline-block;
    @include img_anim(100%, 500px, 130%);

    @media #{$xxl} {
      height: 400px;
    }

    @media #{$xl} {
      height: 400px;
    }

    @media #{$lg} {
      height: unset;

      img {
        position: unset;
        height: auto;
      }
    }

    @media #{$md} {
      height: unset;

      img {
        position: unset;
        height: auto;
      }
    }

    @media #{$sm} {
      height: unset;

      img {
        position: unset;
        height: auto;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    br {
      @media #{$sm} {
        display: block;
      }
    }

    h2 {
      color: var(--black-2);
      font-size: 24px;
      line-height: 1;
      font-weight: 500;
      text-transform: uppercase;

      span {
        color: var(--black-2);
        padding-left: 35px;
        font-family: $font_newyork;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          height: 1px;
          width: 30px;
          background-color: var(--black-13);
        }

        &.before_content_two {
          padding-left: 65px;
          color: var(--white);

          &::before {
            width: 55px;
            background-color: var(--white);
          }
        }
      }
    }

    span {
      text-transform: uppercase;
      color: var(--black-13);
    }
  }
}

.plr-50 {
  padding: 0 50px;
}

.work12__title {
  margin: 0 auto 80px;
  margin-right: 0;
}

.hero {
  &__area {
    &-12 {
      overflow: hidden;
      height: 100%;
      padding: 100px 30px 0;

      .swiper-horizontal>.swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction {
        bottom: 81px;
        left: 0;
        width: 100%;
      }

      .swiper-pagination-bullet-active {
        outline: 1px solid #121212;
        outline-offset: 4px;
        background: #121212;
      }

      .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
      .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 11px;
      }

      .gallery {


        &-slider {
          width: 100%;
          height: auto;
          overflow: hidden;
          padding-top: 80px;
        }

        &-thumbs {
          position: absolute;
          width: 300px;
          height: 100%;
          top: 0;
          right: 0;

          .swiper-slide-active {
            opacity: 1;
          }

          .swiper-slide img {
            width: 150px;
          }

        }


      }


      .gallery-slider .swiper-slide {
        width: auto;
      }


    }
  }
}

.gallary12 {
  display: flex;
  align-items: center;
  background-image: url('../../imgs/bg/bg.png');
  background-position: center;

  @media #{$lg} {
    flex-direction: column;
  }

  &__thumb {
    height: 575px;
    max-width: 865px;

    img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }

    @media #{$xxl} {
      max-width: 850px;
    }

    @media #{$xl} {
      height: 520px;
      max-width: 750px;
    }

    @media #{$lg} {
      height: 460px;
      max-width: 560px;
    }

    @media #{$md} {
      height: 380px;
      max-width: 570px;
    }

    @media #{$sm} {
      max-width: 100%;
      height: 55vh;
    }
  }

  &__content {
    text-transform: uppercase;
    flex-basis: 28%;
    opacity: 0;
    transition: all 0.7s;

    @media #{$xl} {
      flex-basis: 32%;
    }

    @media #{$lg} {
      flex-basis: 30%;
    }

    @media #{$md} {
      flex-basis: 100%;
      margin-bottom: 20px;
    }

    @media #{$sm} {
      flex-basis: 100%;
      margin-bottom: 20px;
    }

    img {
      display: block;
      text-align: left;
      margin-bottom: 40px;

      @media #{$md} {
        margin-bottom: 20px;
      }

      @media #{$sm} {
        margin-bottom: 20px;
      }
    }

    &__title {
      font-size: 60px;
      color: var(--black-2);

      @media #{$xl} {
        font-size: 48px;
      }

      @media #{$lg} {
        font-size: 36px;
      }

      @media #{$md} {
        font-size: 30px;

        br {
          display: none;
        }
      }

      @media #{$sm} {
        font-size: 24px;
      }

      span {
        font-family: $font_newyork;
      }
    }

    &__name {
      color: var(--black-2);
    }
  }
}

.title_right.w-300 {
  width: 300px;
}

.pl-100 {
  padding-left: 100px;
}

.showcase5_title {
  font-size: 80px !important;
  margin-bottom: 20px;

  @media #{$md} {
    font-size: 30px !important;
    margin-bottom: 20px;
  }

  @media #{$sm} {
    font-size: 20px !important;
    margin-bottom: 20px;
  }
}

.showcase5_bottom {
  padding-left: 38px;
  position: relative;
  z-index: 2;

  span {
    color: var(--white);
  }
}

.section_title_wrapperr {
  @media #{$sm} {
    flex-wrap: wrap !important;
  }

  @media #{$md} {
    flex-wrap: wrap !important;
  }
}

.showcase5__content {
  img {
    margin-bottom: 35px;
  }

  p {
    font-weight: 400;
    font-size: 32px;
    line-height: 1.3;
    text-align: center;
    color: var(--black-3);
    position: relative;
    padding-bottom: 190px;

    @media #{$lg} {
      font-size: 30px;
    }

    @media #{$md} {
      font-size: 24px;
    }

    @media #{$sm} {
      font-size: 20px;
      padding-bottom: 100px;
    }

    &::after {
      position: absolute;
      content: "";
      width: 1px;
      height: 100px;
      bottom: 0px;
      left: 50%;
      background-color: var(--black-2);

      @media #{$sm} {
        height: 50px;
      }
    }
  }
}

.logos_wrapper {
  li {
    margin-bottom: 10px;

    a {
      text-transform: capitalize;
      color: var(--black-2);
      font-weight: 500;
    }
  }
}

.logo17 {
  margin-bottom: 90px;
}


// 
// Home 15
.hero__area-15 {
  padding: 0 30px;
  overflow: hidden;

  @media #{$sm} {
    padding: 0 15px;
  }

  &::before {
    content: "";
    display: table;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  .swiper-container {
    width: calc(100% - 160px);
    height: calc(100vh - 120px);
    float: left;
    transition: opacity .6s ease, transform .3s ease;

    @media #{$xl} {
      height: calc(100vh - 80px);
    }

    @media #{$lg} {
      width: calc(100% - 130px);
    }

    @media #{$md} {
      height: calc(100vh - 60px);
      width: calc(100% - 90px);
    }

    @media #{$sm} {
      width: 100%;
      height: calc(100vh - 60px);
    }
  }

  .main-slider {
    padding-top: 160px;

    @media #{$xl} {
      padding-top: 120px;
    }

    @media #{$lg} {
      padding-top: 120px;
    }

    @media #{$md} {
      padding-top: 100px;
    }

    @media #{$sm} {
      padding-top: 120px;
    }

    .swiper-image {
      width: 100%;
      height: 100%;
    }

    .swiper-pagination {
      bottom: 60px;

      @media #{$xxl} {
        bottom: -40px;
      }

      @media #{$xl} {
        bottom: -40px;
      }

      @media #{$lg} {
        bottom: -40px;
      }

      @media #{$md} {
        bottom: -40px;
      }

      @media #{$sm} {
        bottom: -40px;
      }

      &-bullet {
        margin: 0 10px;
        width: 6px;
        height: 6px;

        &-active {
          outline: 1px solid var(--black-2);
          background: var(--black-2);
          outline-offset: 6px;
        }
      }

    }
  }

  .swiper-slide {
    overflow: hidden;

    &.swiper-slide-active {
      .gallary__thumb {
        background-color: transparent;
      }

      .gallary12__content {
        opacity: 1;
      }
    }
  }

  .gallary__thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background-position: center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: hue;
    transition: all 0.5s ease-out;
    padding: 10px 20px;
    color: var(--white);
    display: flex;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
  }

  .swiper-container {
    &.thumb-slider {
      width: 160px;
      overflow: hidden;
      height: calc(100vh - 220px);
      margin-top: 120px;

      @media #{$xl} {
        width: 130px;
        margin-top: 110px;
        height: calc(100vh - 180px);
      }

      @media #{$lg} {
        width: 130px;
        margin-top: 100px;
        height: calc(100vh - 180px);
      }

      @media #{$md} {
        width: 90px;
        margin-top: 110px;
        height: calc(100vh - 180px);
      }

      @media #{$sm} {
        width: 100%;
        margin-top: 30px;
        height: 200px;
        display: none;
      }

      .swiper-slide {
        cursor: pointer;
      }

    }
  }

  .gallary12 {
    flex-direction: unset;

    @media #{$md} {
      flex-direction: column;
    }

    @media #{$sm} {
      flex-direction: column;
    }
  }

}



// Home 16
.showcase {
  &__top-16 {
    padding: 200px 30px 65px;

    @media #{$xxl} {
      padding: 160px 30px 65px;
    }

    @media #{$xl} {
      padding: 160px 30px 65px;
    }

    @media #{$lg} {
      padding: 160px 30px 65px;
    }

    @media #{$md} {
      padding: 160px 30px 60px;
    }

    @media #{$sm} {
      padding: 140px 30px 40px;
    }

    .title-right {
      max-width: 296px;
      margin-left: auto;
      padding-top: 50px;

      @media #{$xxl} {
        padding-top: 40px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  &__content-16 {
    a {
      position: relative;
      display: inline-block;
    }

    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }


  &__info-16 {
    position: absolute;
    bottom: 50px;
    left: 50px;

    @media #{$md} {
      bottom: 30px;
    }

    @media #{$sm} {
      bottom: 20px;
      left: 20px;
    }
  }

  &__title-16 {
    font-weight: 500;
    font-size: 80px;
    line-height: 1;
    text-transform: uppercase;
    color: var(--white);
    padding-bottom: 35px;

    @media #{$xl} {
      font-size: 60px;
    }

    @media #{$lg} {
      font-size: 48px;
    }

    @media #{$md} {
      font-size: 36px;
    }

    @media #{$sm} {
      font-size: 30px;
      padding-bottom: 10px;

      br {
        display: block;
      }
    }

    span {
      display: inline-block;
      text-indent: 100px;
      font-family: $font_newyork;
      position: relative;

      @media #{$sm} {
        text-indent: 30px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        height: 2px;
        width: 70px;
        background-color: var(--white);

        @media #{$sm} {
          width: 20px;
        }
      }
    }
  }

  &__sub-title-16 {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    color: var(--white);
    padding-left: 100px;
    text-transform: uppercase;

    @media #{$sm} {
      padding-left: 30px;
    }
  }

}

.showcase_info {
  display: block;
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 9999;
  background-color: var(--white);
  margin-bottom: -3px;
}

.showcase__info-16 {
  position: absolute;
  bottom: 30%;
  left: 50px;
}

.showcase__image-16 {
  overflow: hidden;
  height: 100vh;
}


// home 16 slider
.showcase__content-16 {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
}




// Tilt CSS
.wc-tilt {
  display: inline-block;
  transform-style: preserve-3d;

  &-2 {
    transform-style: preserve-3d;
  }
}




// Dark Version

.dark {

  .hero__area-10 {
    .hero10_activition {

      .swiper-pagination-current {
        color: var(--white);
      }

      .swiper-pagination-total {
        color: var(--white);
      }
    }

    .swiper-button-prev.hero_prev {
      color: var(--white);

      &:hover {
        color: var(--gray-3);
      }

      img {
        filter: invert(1);
      }
    }

    .swiper-button-next.hero_next {
      color: var(--white);

      &:hover {
        color: var(--gray-3);
      }

      img {
        filter: invert(1);
      }
    }

    .increase-dash {
      background-color: var(--white);
    }
  }

  .showcase4__content {
    h2 {
      color: var(--white);

      span {
        color: var(--white);
      }
    }

    span {
      color: var(--gray-2);
    }
  }

  .showcase5__content {
    p {
      color: var(--gray-3);

      &::after {
        background-color: var(--white);
      }
    }

    img {
      filter: invert(1);
    }
  }

  .showcase_info {
    background-color: var(--black-2);
  }

  .gallary12__content__title,
  .gallary12__content__name {
    color: var(--white);
  }

  .gallary12__content img {
    filter: invert(1);
  }

  .hero__area-15 .main-slider {
    .swiper-pagination-bullet {
      background: var(--white);
      opacity: 0.5;
    }

    .swiper-pagination-bullet-active {
      outline: 1px solid var(--white);
      background: var(--white);
      opacity: 1;
    }
  }

}