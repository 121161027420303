.canvas-list-menu{
    color: white !important; 
}
.header_title {
    padding: 25px;
}
.offcanvas__body {
    grid-template-columns: 1fr;
}

.canvas-img{
  padding: 18px;
}
@media (max-width: 1199px) and (min-width: 768px){
    .offcanvas__area {
        background-color: var(--black-2)!;
        position: fixed;
        width: 50% !important;
        height: 100vh !important;
        left: 0;
        top: 0;
        z-index: 9999;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
    }
}

@media (max-width: 767px) and (min-width: 300px){
    .offcanvas__area {
        background-color: var(--black-2);
        position: fixed;
        width: 90%;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 9999;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
    }
}