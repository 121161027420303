/*----------------------------------------*/
/*  37. HOME 5 CSS START
/*----------------------------------------*/



// Colors
:root {
  // new
  --white-6: #d7d7d7;
  --white-7: #B7B7C5;

  --black-14: #323438;

  --lime: #745EFF;
  --lime-2: #5547F5;
  --gray-16: #6B6B6B;
  --gray-17: #F7F7FC;
  --gray-18: #999999;
  --gray-20: #D7D7D7;
  --gray-21: #F2F2F8;
  --gray-22: #FCFCFE;
  --gray-23: #ECECF3;
  --gray-24: #ECECF4;
  --gray-25: #D3D3D3;

}

// button css
.btn {

  &-together {
    font-weight: 400;
    line-height: 1.3;
    color: var(--white);
    position: relative;
    transition: all 0.5s;
    font-size: 18px;
    text-transform: uppercase;

    @media #{$sm} {
      font-size: 14px;
    }

    @media #{$md} {
      font-size: 15px;
    }

    i {
      padding-left: 10px;
    }

    &::after {
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--white);
      content: "";
      transition: all 0.5s;
    }

    &:hover::after {
      left: 0;
      width: 1%;
    }

    &:hover {
      color: var(--gray);
    }
  }

}

// Hero area
.header {

  &__inner-5 {
    padding: 25px 50px 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s;

    @media #{$sm} {
      padding: 20px 15px;
    }

    @media #{$md} {
      padding: 25px 15px 22px;
    }

    @media #{$lg} {
      padding: 25px 15px 22px;
    }
  }

  &__area-5 {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99;

    &.sticky-5 {
      background-color: var(--white);
      border-bottom: 1px solid var(--white-2);

      .header__inner-5 {
        padding: 20px 50px;

        @media (max-width: 1200px) {
          padding: 20px 15px;
        }
      }
    }
  }
}

// offcanvas
.menu-text {
  &-5 {
    color: var(--black-2);
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    padding-right: 10px;
  }

  &-pp {
    color: var(--black-2);
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    padding-right: 10px;
  }
}

// Hero area
.hero {
  &__area {
    &-5 {
      padding: 190px 35px 113px;
      position: relative;

      @media #{$sm} {
        padding: 135px 0px 60px;
      }

      @media #{$md} {
        padding: 145px 0px 60px;
      }

      @media #{$lg} {
        padding: 155px 0px 70px;
      }

      @media #{$xl} {
        padding: 166px 0px 80px;
        background-size: 90%;
      }

      @media #{$xxl} {
        padding: 185px 0px 80px;
        background-size: 90%;
      }

      img {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        filter: invert(1);
        z-index: 0;
      }
    }
  }

  &__content-5 {

    p {
      font-size: 18px;
      color: var(--black-2);
      font-weight: 400;
      line-height: 1.4;
      max-width: 330px;

      @media #{$sm} {
        font-size: 16px;
      }
    }
  }

  &__text-5 {
    display: flex;
    justify-content: flex-end;
    padding-top: 25px;
  }

  &__title-5 {
    font-size: 80px;
    font-weight: 500;
    line-height: 1;
    color: var(--black-2);
    text-transform: uppercase;

    @media #{$sm} {
      font-size: 30px;
      line-height: 1.2;
    }

    @media #{$md} {
      font-size: 36px;
      line-height: 1.2;
      padding-bottom: 20px;
    }

    @media #{$lg} {
      font-size: 40px;
      line-height: 1.4;
      padding-bottom: 20px;
    }

    @media #{$xl} {
      font-size: 45px;
      line-height: 1.4;
    }

    @media #{$xxl} {
      font-size: 50px;
      line-height: 1.4;
    }
  }

}



//Portfolio area
.portfolio {
  &__area {
    &-5 {
      border-top: 1px solid var(--gray-25);
      border-bottom: 1px solid var(--gray-25);
      padding: 56px 45px;
      position: relative;
      overflow: hidden;

      @media #{$sm} {
        padding: 56px 0px;
      }

      @media #{$md} {
        padding: 56px 0px;
      }

      @media #{$lg} {
        padding: 56px 0px;
      }

      &::before {
        position: absolute;
        left: 33.3%;
        top: 0;
        width: 1px;
        height: 100%;
        content: "";
        background-color: var(--secondary);
        filter: invert(1);
      }

      &::after {
        position: absolute;
        left: 66.6%;
        top: 0;
        width: 1px;
        height: 100%;
        content: "";
        background-color: var(--secondary);
        filter: invert(1);
      }

      .sec-line {
        position: absolute;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 1px;
        content: "";
        background-color: var(--secondary);
        filter: invert(1);
      }

    }
  }

  &__inner-5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 110px;
    flex-wrap: wrap;

    @media #{$sm} {
      gap: 20px;
    }

    @media #{$md} {
      gap: 20px;
    }

    @media #{$lg} {
      gap: 40px;
    }

    @media #{$xl} {
      gap: 70px;
    }

    @media #{$xxl} {
      gap: 80px;
    }
  }

  &__item-5 {
    width: 29%;
    position: relative;
    transition: all 0.5s;

    @media #{$sm} {
      width: 100%;
    }

    @media #{$md} {
      width: 48.5%;
    }

    @media #{$lg} {
      width: 48%;
    }

    @media #{$xl} {
      width: 28%;
    }

    @media #{$xxl} {
      width: 28%;
    }

    &:hover {
      .portfolio__content-5 {
        opacity: 1;
        right: -50px
      }
    }

    img {
      width: 100%;
      max-width: 100%;
    }

  }

  &__content-5 {
    background-color: var(--black-2);
    padding: 20px 30px 22px 30px;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: -70px;
    z-index: 9;
    opacity: 0;
    transition: all 0.5s;

    @media #{$sm} {
      right: 0;
      opacity: 1;
    }

    @media #{$md} {
      right: 0;
      opacity: 1;
    }

    @media #{$lg} {
      right: -70px;
    }
  }

  &__name-5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    padding-bottom: 2px;
    color: var(--white);
    text-transform: uppercase;

    @media #{$sm} {
      font-size: 12px;
    }
  }

  &__title-5 {
    font-size: 24px;
    font-weight: 500;
    color: var(--white);
    text-transform: uppercase;

    @media #{$sm} {
      font-size: 18px;
    }

    @media #{$md} {
      font-size: 18px;
    }

    @media #{$lg} {
      font-size: 20px;
    }
  }
}


//Cta area
.cta {
  &__area {
    &-5 {
      position: relative;
      border-bottom: 1px solid var(--secondary);
      filter: invert(1);

      &::before {
        position: absolute;
        top: 0;
        left: 57%;
        width: 1px;
        height: 100%;
        content: "";
        background-color: var(--secondary);
      }
    }
  }

  &__inner-5 {
    max-width: 1410px;
    margin: 0 auto;
  }

  &__title-5 {
    font-size: 60px;
    color: var(--white);
    font-weight: 500;
    line-height: 1.2;
    padding-bottom: 28px;
    text-transform: uppercase;

    @media #{$sm} {
      font-size: 30px;
    }

    @media #{$md} {
      font-size: 36px;

      br {
        display: none;
      }
    }

    @media #{$lg} {
      font-size: 40px;
    }

    @media #{$xl} {
      font-size: 45px;
    }

    @media #{$xxl} {
      font-size: 50px;
    }

  }

  &__list {
    &-wrapper {
      display: grid;
      grid-template-columns: 230px auto;
      gap: 30px;

      @media #{$sm} {
        grid-template-columns: 1fr;
        margin-bottom: 50px;
      }

      @media #{$md} {
        grid-template-columns: 180px auto;
        gap: 20px;
      }
    }
  }

  &__list-left {
    padding-left: 15px;

    li {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: -15px;
        width: auto;
        height: auto;
        content: "+";
      }
    }
  }

  &__item-5 {
    h3 {
      font-size: 18px;
      font-weight: 400;
      color: var(--white);
      padding-bottom: 10px;
      text-transform: uppercase;
    }

    li {
      font-size: 18px;
      color: var(--gray-2);
      line-height: 1.5;
      font-weight: 400;

      @media #{$sm} {
        font-size: 16px;
      }

      @media #{$md} {
        font-size: 16px;
      }
    }
  }

  &__btn-5 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media #{$sm} {
      justify-content: flex-start;
    }
  }
}

//Footer area
.footer {
  &__area {
    &-5 {
      padding: 79px 0px 72px;
      text-align: center;

      @media #{$lg} {
        padding: 60px 0px;
      }

      @media #{$md} {
        padding: 50px 0px;
      }

      @media #{$sm} {
        padding: 50px 0px;
      }
    }
  }

  &__menu-5 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    flex-wrap: wrap;

    @media #{$sm} {
      gap: 25px;
    }

    li {
      a {
        color: var(--gray-2);
        font-size: 15px;
        line-height: 1;
        font-weight: 400;
        text-transform: uppercase;
        transition: all 0.5s;

        @include menu_anim(var(--black-2));
      }
    }
  }

  &__copyright-4 {
    color: var(--gray-2);
    font-size: 16px;
    font-weight: 400;

    a {
      color: var(--black-2);

      &:hover {
        color: var(--gray);
      }
    }
  }
}