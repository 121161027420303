/*----------------------------------------*/
/*  07. Button CSS START
/*----------------------------------------*/


.wc-btn {
  &-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 130px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    text-transform: capitalize;
    color: var(--black-3);
    border: 1px solid var(--gray);
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;

    @media #{$sm} {
      padding: 30px;
    }

    &:hover {
      color: var(--black-2);
      border: 1px solid var(--primary);

      span {
        width: 350px;
        height: 350px;
      }
    }

    i {
      transform: rotate(-45deg);
      padding-left: 5px;
    }

    span {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      left: 0;
      top: 0;
      background-color: var(--primary);
      z-index: -1;
      border-radius: 100%;
      transition: all 0.7s;
      transform: translate(-50%, -50%);
    }
  }

  &-secondary {
    @extend .wc-btn-primary;
    border: 1px solid var(--secondary);
    color: var(--gray-2);
  }

  &-pink {
    @extend .wc-btn-primary;
    border: 1px solid var(--secondary);
    color: var(--gray-2);

    &:hover {
      border-color: var(--pink-2);
    }

    span {
      background-color: var(--pink-2);
    }
  }

  &-light {
    @extend .wc-btn-primary;
    border: 1px solid var(--secondary);
    color: var(--gray-2);

    &:hover {
      border-color: var(--white);
    }

    span {
      background-color: var(--white);
    }
  }

  &-black {
    @extend .wc-btn-primary;

    &:hover {
      color: var(--white);
      border-color: var(--black-2);
    }

    span {
      background-color: var(--black-2);
    }
  }

  &-dark {
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
    color: var(--black-2);
    border: 1.3px solid var(--black-2);
    border-radius: 33px;
    padding: 20px 54px;
    transition: all 0.3s;

    &:hover {
      color: var(--black-2);
      background-color: var(--white);
      border-color: var(--white);
    }
  }
}



// Button Animation
.btn_wrapper,
#btn_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 250px;
  width: 250px;
  border-radius: 100%;
  margin-left: -40px;

  @media #{$xl} {
    width: 220px;
    height: 220px;
    margin-left: -25px;
  }

  @media #{$lg} {
    width: 190px;
    height: 190px;
    margin-left: -10px;
  }

  @media #{$md} {
    width: 180px;
    height: 180px;
    margin-left: -5px;
  }

  @media #{$sm} {
    height: 175px;
    width: 175px;
    margin-left: -2.5px;
  }
}

.btn-item {
  position: absolute;
}

@media #{$md} {
  .service__top-btn {

    .btn_wrapper,
    #btn_wrapper {
      width: 170px;
      height: 170px;

      .btn-item {
        top: 0;
        left: 0;
      }
    }
  }
}

.about_btn {
  font-size: 20px !important;
  position: relative;

  @media #{$sm} {
    font-size: 18px !important;
  }

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    bottom: -10px;
    left: 0;
    background-color: var(--black-7);
  }
}