.map-content-area {
  background-color: var(--green-2);
  padding-top: 30px;
  padding-bottom: 30px;
}
.map-contetnt-wrapper {
  display: flex;
  width: 100%;
  padding: 50px;
}
.map-content {
  width: 50%;
  padding: 50px;
}
.map {
  width: 50%;
  padding: 50px;
  /* padding-top: 100px; */
}
.dholera-map-txt {
    max-width: 745px;
    font-size: 18px;
    line-height: 1.8;
    color: var(--black-13);
    padding-bottom: 30px;
    padding-left: 20px;

}
.heading-dholera-content-txt-map{
    font-size: 56px;
    padding-bottom: 50px;
  font-weight: 600;

}
.svg-bollet-dholera{
  display: flex;
}
.svg-pad-t{
  padding-top: 3px;
}
@media (min-width: 1180px) and (max-width:1440px){
  .map.col-xxl-7.col-xl-7.col-lg-6.col-md-6 img {
    width: 100% !important;
}
.map {
  width: 50%;
  padding: 50px;
  padding-top: 190px;
}
}

@media (min-width: 767px) and (max-width: 1199px) {
  .map-contetnt-wrapper {
    display: flex;
    width: 100%;
    padding: 50px;
    flex-direction: column;
}
.map-content {
  width: 100%;
  padding: 0px;
}
.map {
  width: 100%;
  padding: 0px 80px;
}
}
@media (max-width: 1024px) {
  .map {
    width: 100%;
    padding: 0px 30px;
}
}
@media (max-width: 768px) {
  img.service-mid-img.map-img-mobile {
    height: 60% !important;
    width: 100% !important;
}
}
@media (max-width: 767px) and (min-width: 320px) {
  .map-contetnt-wrapper {
    display: flex;
    width: 100%;
    padding: 0px;
    flex-direction: column;
}
.heading-dholera-content-txt-map {
  font-size: 30px;
  padding-bottom: 50px;
}
.map-content {
  width: 100%;
  padding: 0px;
}
img.service-mid-img.map-img-mobile {
  top: 0px;
}
.map-content-area {
    background-color: var(--green-2);
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}
}

@media (min-width: 800px) and (max-width:930px){
  img.service-mid-img.map-img-mobile {
    width: 100% !important;
}
.contact__form #btn_wrapper, .contact__form .btn_wrapper {
  margin-top: -29px !important;
  margin-bottom: -25px !important;
}
}
@media (max-width: 1600px){
  img.service-mid-img.map-img-mobile {
    width: 100% !important;
    padding-top: 180px;
}
}
