/*----------------------------------------*/
/*  21. TESTIMONIAL CSS START
/*----------------------------------------*/


.testimonial {
  &__area {
    overflow: hidden;

    .sec-title-wrapper {
      max-width: 330px;
      padding-top: 120px;

      @media #{$lg} {
        width: 100%;
        padding-top: 60px;
        padding-left: 30px;
      }

      @media #{$md} {
        padding-top: 0px;
      }

      @media #{$sm} {
        padding-top: 0px;
      }
    }

    p {
      padding-top: 30px;
      font-size: 18px;
      line-height: 1.4;

      @media #{$lg} {
        font-size: 16px;
      }

      @media #{$sm} {
        padding-bottom: 20px;
      }
    }

    &-2 {

      .line-3 {
        z-index: 5;
      }

      .g-0 {
        @media #{$sm} {
          padding-right: 8px;
          padding-left: 8px;
        }
      }
    }

    &-3 {
      background-image: url(../../imgs/testimonial/3/bg.png);
      background-repeat: no-repeat;
      background-position: 0 150px;
      position: relative;
      padding-top: 410px;
      padding-bottom: 330px;
      overflow: hidden;

      @media #{$lg} {
        padding-top: 300px;
        padding-bottom: 250px;
      }

      @media #{$md} {
        padding-top: 300px;
        padding-bottom: 200px;
      }

      @media #{$sm} {
        padding-top: 60px;
        padding-bottom: 60px;

        img {
          display: none;
        }
      }
    }

    @media (max-width: 1023px) {
      .cursor {
        display: none;
      }
    }
  }

  &__slider {

    &-3 {
      padding-top: 120px;
      padding-bottom: 120px;
      max-width: 660px;

      @media #{$sm} {
        padding-top: 80px;
      }

      .swipper-btn {
        width: 50px;
        height: 50px;
        border: 1px solid;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        transition: all 0.3s;

        &:hover {
          background-color: var(--black-2);

          i {
            color: var(--white);
          }
        }

        i {
          font-size: 18px;
          color: var(--black-3);
        }
      }

      .next-button {
        right: calc(50% - -10px);
      }

      .prev-button {
        left: calc(50% - -10px);
      }
    }
  }

  &__slide {
    margin-left: -3px;

    &-3 {
      text-align: center;

      p {
        @include paragraph(24px, var(--black-2));
        font-style: italic;
        max-width: 630px;
        margin: 0 auto;
        position: relative;

        @media #{$md} {
          font-size: 20px;
        }

        @media #{$sm} {
          font-size: 20px;
          padding: 0 10px;
        }

        &::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          top: -100px;
          left: calc(50% - 23px);
          background-image: url(../../imgs/icon/quote.png);
          background-repeat: no-repeat;

          @media #{$sm} {
            top: -80px;
          }
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @media #{$md} {
      flex-direction: column-reverse;
    }

    @media #{$sm} {
      flex-direction: column-reverse;
    }

    &-2 {
      position: relative;
    }
  }

  &__item {
    @media #{$sm} {
      padding-bottom: 30px;
    }

    .button {
      display: inline-block;
      cursor: pointer;
    }


    &.item {
      &-1 {
        @media #{$sm} {
          text-align: center;
          margin: 0 50px;

          img {
            width: 100%;
          }
        }
      }

      &-2 {
        padding-top: 20px;
        text-align: center;
      }

      &-3 {
        padding-top: 220px;
        text-align: right;

        @media #{$sm} {
          text-align: center;
          padding-top: 30px;
        }
      }
    }
  }

  &__img {
    position: relative;
    margin-bottom: 20px;

    img {
      @media #{$lg} {
        width: 100%;
      }

      @media #{$md} {
        width: 100%;
      }
    }

    &.b-right::after {
      position: absolute;
      content: "";
      width: 100%;
      height: calc(100% - 30px);
      left: 30px;
      top: 0;
      border: 1px solid var(--white-6);
      border-radius: 180px 180px 0 0;
      z-index: -1;
    }

    &.b-left::before {
      position: absolute;
      content: "";
      width: 100%;
      height: calc(100% - 30px);
      left: -30px;
      top: 0;
      border: 1px solid var(--white-6);
      border-radius: 180px 180px 0 0;
      z-index: -1;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.3;
    color: var(--black);
    text-transform: capitalize;

    &-2 {
      font-weight: 500;
      font-size: 60px;
      line-height: 1;
      color: var(--black);
      font-style: italic;
      padding: 100px 100px 20px 0;

      @media #{$xl} {
        font-size: 48px;
        padding-top: 80px;
      }

      @media #{$lg} {
        font-size: 48px;
        padding-right: 30px;
      }

      @media #{$md} {
        font-size: 36px;
        padding-right: 30px;
      }

      @media #{$sm} {
        font-size: 36px;
        padding: 20px 0;
      }
    }
  }

  &__role {
    font-size: 14px;
    color: var(--black-3);
    line-height: 1.3;
    font-weight: 400;
    text-transform: capitalize;

    &-2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 12px;
      color: var(--black-3);
    }
  }

  &__text-2 {
    font-weight: 300;
    font-size: 22px;
    line-height: 1.4;
    color: var(--black-3);
    padding-right: 40px;
    padding-bottom: 50px;

    @media #{$lg} {
      font-size: 20px;
    }

    @media #{$md} {
      padding-right: 0;
      padding-bottom: 30px;
      font-size: 18px;
    }

    @media #{$sm} {
      font-size: 18px;
      padding-right: 0;
      padding-bottom: 30px;
    }
  }

  &__inner {
    &-2 {
      background-color: var(--white);
      padding: 80px;
      margin-top: 140px;
      background-image: url(../../imgs/icon/quote-primary.png);
      background-repeat: no-repeat;
      background-position: 80px 80px;
      margin-right: 2px;

      @media #{$xl} {
        padding: 80px 30px;
        margin-top: 185px;
      }

      @media #{$lg} {
        padding: 60px 30px;
      }

      @media #{$md} {
        padding: 60px 30px;
        margin-top: 136px;
      }

      @media #{$sm} {
        text-align: center;
        padding: 0;
        padding-top: 60px;
        margin-top: 30px;
        background-position: 50% 0;
      }
    }
  }

  &__video {
    margin-right: -112px;
    max-height: 785px;
    overflow: hidden;

    @media #{$lg} {
      max-height: 740px;
    }

    @media #{$md} {
      max-height: 700px;
    }

    @media #{$sm} {
      margin-right: 0;
      max-height: unset;
    }

    video {
      width: 100%;
    }
  }

  &__author {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.3;
    color: var(--black-2);
    padding-bottom: 5px;
  }


  &__pagination {
    position: absolute;
    right: 0;
    top: calc(50% - 105px);
    transform: translate(100%, -50%);

    @media #{$xxl} {
      transform: unset;
      z-index: 9;
    }

    @media #{$xl} {
      transform: unset;
      top: unset;
      bottom: 110px;
      z-index: 9;
    }

    @media #{$lg} {
      transform: unset;
      top: unset;
      bottom: 60px;
      z-index: 9;
    }

    @media #{$md} {
      transform: unset;
      top: unset;
      bottom: 60px;
      z-index: 9;
    }

    @media #{$sm} {
      display: none;
    }

    .next-button,
    .prev-button {
      width: 60px;
      height: 60px;
      border: 1px solid var(--gray-7);
      color: var(--gray-7);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px 35px;
      transition: all 0.3s;
      position: relative;

      @media #{$md} {
        margin: 20px;
      }

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }

      &:hover {
        border-color: var(--black-2);

        i {
          color: var(--black-2);
        }
      }
    }
  }

  &3__img {
    position: absolute;
    top: 170px;
    left: 25%;

    @media #{$lg} {
      top: 100px;
      left: 15%;
    }

    @media #{$md} {
      top: 60px;
      left: 10%;
    }

    &-2 {
      position: absolute;
      top: 230px;
      right: 28%;

      @media #{$lg} {
        top: 180px;
        right: 15%;
      }

      @media #{$md} {
        top: 130px;
        right: 15%;
      }
    }

    &-3 {
      position: absolute;
      top: 40%;
      left: 50px;

      @media #{$md} {
        top: 78%;
        left: 60px;
        z-index: 9;
      }
    }

    &-4 {
      position: absolute;
      bottom: 150px;
      left: 170px;

      @media #{$xxl} {
        left: 80px;
        max-width: 250px;
      }

      @media #{$xl} {
        left: 70px;
        max-width: 220px;
      }

      @media #{$lg} {
        bottom: 70px;
        left: 70px;
        max-width: 200px;
      }

      @media #{$md} {
        bottom: 180px;
        left: 0;
        max-width: 160px;
      }
    }

    &-5 {
      position: absolute;
      top: 43%;
      right: 70px;

      @media #{$xl} {
        right: 10px;
      }

      @media #{$lg} {
        top: 62%;
        right: 30px;
      }

      @media #{$md} {
        top: 60%;
        right: 0;
        width: 150px;
      }
    }

    &-6 {
      position: absolute;
      right: 280px;
      top: 58%;

      @media #{$xl} {
        right: 200px;
      }

      @media #{$lg} {
        top: 75%;
        right: 200px;
      }

      @media #{$md} {
        top: 70%;
        right: 60px;
      }
    }
  }
}

// Client
.client {
  &__name {
    &-3 {
      font-weight: 500;
      font-size: 28px;
      line-height: 1.3;
      text-transform: uppercase;
      color: var(--black-2);
      padding-top: 50px;
      padding-bottom: 10px;
    }
  }

  &__role {
    &-3 {
      font-weight: 300;
      font-size: 14px;
      line-height: 1.5;
      text-transform: uppercase;
      color: var(--black-3);
    }
  }
}