.privacy-policy-container{
    max-width: 1440px;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 50px;
}
.privacy-policy-txt-heading-main{
    color: var(--black-2);
    font-size: 45px;
    font-weight: 500;
    line-height: 65px;
    padding-bottom: 15px;
}
.privacy-policy-txt-p{
    color: var(--black-3);
    font-size: 18px;
    font-weight: 400;
    line-height: 1.8;
    padding-bottom: 19px;
    text-align: left;
}
.policy-ul li {
    list-style-type: disc;
    margin-left: 40px;
}
.privacy-policy-txt-heading{
    color: var(--black-2);
    font-size: 35px;
    font-weight: 500;
    line-height: 65px;
    padding-bottom: 15px;
}
@media (min-width: 768px) and (max-width: 1199px){
    .privacy-policy-txt-heading{
         font-size: 30px;
 
    }
}
@media (min-width: 320px) and (max-width: 767px){
    .privacy-policy-txt-heading{
         font-size: 25px;
 
    }
}