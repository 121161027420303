.choose-title {
    font-weight: 500;
    font-size: 120px;
    line-height: 1.2;
   color: var(--black);
   text-transform: none !important;
}
.research__area .sec-title {
    text-transform: none;
    padding-bottom: 50px;
    font-size: 40px;
    line-height: 1.2;
}
.research__area .sec-sub-title {
    font-weight: 500;
    text-transform: uppercase;
    color: var(--black);
    padding-bottom: 60px;
}
.research__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 1.4;
    text-transform: none;
    color: var(--black-2);
    padding-bottom: 15px;
}
.research__item {
    display: flex;
    align-items: center;
    gap: 30px;
    padding-bottom: 23px;
}
/* .btn-invest{
    position: relative;
    z-index: 1;
    width: 190px !important;
    height: 240px !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: -60px;
} */
.portfolio__service-item .ps-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.4;
    text-transform: none;
    color: var(--black-2);
    padding-bottom: 20px;
    transition: all 0.3s;
}
.new-wf-panel{
    margin-top: 20px; /* Adjust the margin-top as needed */
    padding: 20px; /* Adjust the padding as needed */
    background-color: #f0f0f0; /* Set a background color for visibility */
}
.counter__img-3 img {
    width: 50%;
    float: left;
}
img.img-founder {
    width: 45%;
    margin-left: 30px;
    margin-right: 30px;
}
.founder-txt {
    margin-top: 130px;
}
.founder-choose-us{
    margin-top: 15%;
    margin-bottom: -114px;
}
.btn-choose-contact{
    top: 0px !important;
}
@media (min-width: 320px) and (max-width: 767px){
    .choose-title {
        font-weight: 500;
        font-size: 50px;
        line-height: 1.2;
        color: var(--black);
        text-transform: none !important;
    }
    .workflow__wrapper-3 {
        width: 400%;
        height: 100%;
        display: contents !important;
        flex-wrap: no-wrap;
    }
    .research__area .sec-title {
        text-transform: none;
        padding-bottom: 50px;
        font-size: 31px;
        line-height: 1.2;
    }
    .counter__wrapper-3 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        height: 100%;
        margin-top: 20px;
        /* padding-left: 15px;
        padding-right: 15px; */
    }
    .counter__img-3 img {
        width: 97%;
        margin-top: 65px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .cta__title-2 {
        font-weight: 300;
        font-size: 30px;
        line-height: 1.2;
        color: var(--black-2);
        padding-bottom: 110px;
    }
    .research__number span {
        width: 100px;
        height: 100px;
        font-size: 16px;
    }
    .counter-txt-3{
        text-align: center;
    }
    .research__number{
        display: inline-block;
        text-align: center;
        padding-bottom: 20px;
    }
    .research__item{
        display: inline-block;
        text-align: center;
    }
    .research__area .sec-title-wrapper p {
        max-width: 380px;
        font-size: 18px;
        line-height: 1.4;
        color: var(--black-2);
    }
    .research__list {
        padding-bottom: 35px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .workflow__area-3 .wf_panel {
        width: 100%;
        height: 100%;
        will-change: transform;
        opacity: 1 !important;
        /* padding-left: 15px;
        padding-right: 15px; */
    }
    .counter__wrapper-3 {
        align-items: flex-start;
        gap: 35px 18px;
    }
    .cta__content-2 a, .cta__content-3 a {
        width: 155px !important;
        height: 155px !important;
        font-size: 14px;
    }
    .pb-150 {
        padding-bottom: 15px;
    }
    .counter__img-3 img {
        width: 83%;
        margin-top: 65px;
        padding-left: 14px;
        padding-right: 15px;
        margin-bottom: 40px;
    }
    .founder-txt {
        width: 100% !important;
    }
 
}
@media (min-width: 426px) and (max-width: 500px){
    a.wc-btn-black.btn-hover.btn-item.btn-choose-contact {
        height: 135px !important;
        width: 135px !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .choose-title {
        font-weight: 500;
        font-size: 90px;
        line-height: 1.4;
        color: var(--black);
        text-transform: none !important;
        margin-bottom: 100px;
    }
    .workflow__wrapper-3 {
        width: 400%;
        height: 100%;
        display: contents !important;
        flex-wrap: no-wrap;
    }
    .research__area .sec-title {
        text-transform: none;
        padding-bottom: 50px;
        font-size: 35px;
        line-height: 48px;
    }
    .counter__wrapper-3 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        height: 100%;
        margin-top: 20px;
    }
    .research__area {
        background-image: none;
    }
    .research__area .sec-title-wrapper {
        padding-right: 0px;
        padding-bottom: 100px;
    }
    .research__area .sec-title-wrapper p {
        max-width: 100%;
        font-size: 18px;
        line-height: 1.4;
        color: var(--black-2);
        margin-left: 0px;
    }
    .counter__img-3 img {
        width: 77%;
        margin-top: 60px;
    }
    .counter__number {
        font-size: 35px;
    }
    .counter__item-3 p {
        font-size: 14px;
    }
    .cta__title-2 {
        font-size: 35px;
        padding-bottom: 40px;
    }
    .cta__content-2 a, .cta__content-3 a {
        width: 175px;
        height: 175px;
        font-size: 18px;
    }
    .btn-contact {
        margin-top: 30px;
        left: 298px;
    }
    .btn_wrapper {
        margin-left: -22px !important;
    }
    .container.inner_content {
        padding-left: 30px;
        padding-right: 30px;
    }
    .counter__area-3.wf_panel {
        padding-left: 20px;
        padding-right: 20px;
    }
    .research__area.pt-150 {
        padding-top: 60px;
        padding-bottom: 100px;
    }
    .pt-150 {
        padding-top: 107px;
    }
    a.wc-btn-primary.btn-hover.btn-item.btn-footer {
        height: 170px !important;
        width: 170px !important;
        margin-top: 48px;
    }
    .col-xxl-5.col-xl-5.col-lg-5.col-md-5.founder-box {
        width: 64%;
    margin-left: 185px;
    }
    .founder-txt {
        margin-top: 120px;
        width: 169% !important;
    }
    a.wc-btn-black.btn-hover.btn-item.btn-choose-contact {
        height: 150px !important;
        width: 150px !important;
    }
}

@media (min-width: 1023px) and (max-width: 1199px) {
    .choose-title {
        font-weight: 500;
        font-size: 90px;
        line-height: 1.4;
        color: var(--black);
        text-transform: none !important;
        margin-bottom: 100px;
    }
    .workflow__wrapper-3 {
        width: 400%;
        height: 100%;
        display: contents !important;
        flex-wrap: no-wrap;
    }
    .research__area .sec-title {
        text-transform: none;
        padding-bottom: 50px;
        font-size: 35px;
        line-height: 48px;
    }
    .counter__wrapper-3 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        height: 100%;
        margin-top: 20px;
    }
    .research__area {
        background-image: none;
    }
    .research__area .sec-title-wrapper {
        padding-right: 0px;
        padding-bottom: 100px;
    }
    .research__area .sec-title-wrapper p {
        max-width: 100%;
        font-size: 18px;
        line-height: 1.4;
        color: var(--black-2);
        margin-left: 0px;
    }
    .counter__img-3 img {
        width: 100%;
        margin-top: 0px;
    }
    .counter__number {
        font-size: 45px;
    }
    .counter__item-3 p {
        font-size: 18px;
    }
    .cta__title-2 {
        font-size: 45px;
        padding-bottom: 40px;
        line-height: 1.4;
    }
    .cta__content-2 a, .cta__content-3 a {
        width: 175px;
        height: 175px;
        font-size: 18px;
    }
    .btn-contact {
        margin-top: 30px;
        left: 298px;
    }
    .btn_wrapper {
        margin-left: -22px !important;
    }
    .container.inner_content {
        padding-left: 30px;
        padding-right: 30px;
    }
    .counter__area-3.wf_panel {
        padding-left: 20px;
        padding-right: 20px;
    }
    .research__area.pt-150 {
        padding-top: 60px;
        padding-bottom: 100px;
    }
    .pt-150 {
        padding-top: 107px;
    }
    .tab-inner-box{
        display: flex;
        flex-direction: column;
    }
    .col-xxl-6.col-xl-6.col-lg-6 {
        width: 100%;
    }
    .research__item {
        display: flex;
        align-items: center;
        gap: 70px;
        padding-bottom: 50px;
    }
    .research__item:nth-child(odd) {
        padding-left: 100px;
    }
    a.wc-btn-primary.btn-hover.btn-item.btn-footer {
        height: 170px;
        width: 170px;
        margin-top: 48px;
    }
    .research__area.wf_panel.pt-150 {
        padding-top: 0px;
    }
    .founder-txt {
        margin-top: 47px !important;
        width: 107%;
    }
    img.img-founder {
        width: 52%;
        margin-left: -25px;
        margin-right: 17px;
    }
    .founder-box{
        width: 39.666667%;

    }
}

/* @media (max-width: 1440px) {
    .workflow__area-3 .wf_panel {
        height: 100%;
        opacity: 1 !important;
        width: 100%;
        will-change: transform;
    }
    .choose-wrapper {
        align-items: center;
        display: flex;
    }
    .founder-choose-us {
        margin-top: 5%;
        margin-bottom: -114px;
    }
    .cta__title-2 {
        font-weight: 300;
        font-size: 83px;
        line-height: 1;
        color: var(--black-2);
        padding-bottom: 60px;
    }
} */

@media (min-width: 1300px) and (max-width: 1360px){
    a.wc-btn-black.btn-hover.btn-item {
        height: 200px !important;
        width: 200px !important;
    }
}

@media  (max-width: 1366px){
    .founder-txt {
        margin-top: 130px;
        width: 107%;
    }
}
@media  (min-width: 1400px) and (max-width: 1440px){
    .research__item {
        display: flex;
        align-items: center;
        gap: 30px;
        padding-bottom: 0px;
    }
    .research__number span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.1;
        text-transform: uppercase;
        color: var(--black-2);
        width: 100px;
        height: 100px;
        border: 3px solid;
        border-radius: 100%;
    }
    .research__title {
        font-weight: 500;
        font-size: 22px;
        line-height: 1.4;
        text-transform: none;
        color: var(--black-2);
        padding-bottom: 15px;
    }
    .research__area .sec-title-wrapper p {
        max-width: 360px;
        font-size: 16px;
        line-height: 1.8;
        color: var(--black-2);
        margin-left: 80px;
    }
    .research__area .sec-title {
        text-transform: none;
        padding-bottom: 50px;
        font-size: 33px;
        line-height: 1.2;
    }
    .service__title-6 {
        font-size: 56px;
    }
    .cta__title-2 {
        font-weight: 300;
        font-size: 83px;
        line-height: 1;
        color: var(--black-2);
        padding-bottom: 60px;
    }
}
@media  (min-width: 1200px) and (max-width: 1299px){
    .research__item {
        display: flex;
        align-items: center;
        gap: 30px;
        padding-bottom: 0px;
    }
    .research__number span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.1;
        text-transform: uppercase;
        color: var(--black-2);
        width: 100px;
        height: 100px;
        border: 3px solid;
        border-radius: 100%;
    }
    .research__title {
        font-weight: 500;
        font-size: 22px;
        line-height: 1.4;
        text-transform: none;
        color: var(--black-2);
        padding-bottom: 15px;
    }
    .research__area .sec-title-wrapper p {
        max-width: 360px;
        font-size: 16px;
        line-height: 1.8;
        color: var(--black-2);
        margin-left: 80px;
    }
    .research__area .sec-title {
        text-transform: none;
        padding-bottom: 50px;
        font-size: 33px;
        line-height: 1.2;
    }
    .service__title-6 {
        font-size: 56px;
    }
}
