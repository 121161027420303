/* HeroSlider.css */
/* Set max-width for images and adjust other styles based on your needs */
.slider-span-line {
  position: absolute;
  left: 16.5%;
  top: 0px;
  height: 100%;
  width: 1px;
  background: rgba(255, 255, 255, 0.15);
  z-index: 2;
}
.slider-span-second-line {
  position: absolute;
  left: 39%;
  top: 0px;
  height: 100%;
  width: 1px;
  background: rgba(255, 255, 255, 0.15);
  z-index: 2;
}
.slider-span-third-line {
  position: absolute;
  left: 61.5%;
  top: 0px;
  height: 100%;
  width: 1px;
  background: rgba(255, 255, 255, 0.15);
  z-index: 2;
}
.slider-span-forth-line {
  position: absolute;
  left: 85%;
  top: 0px;
  height: 100%;
  width: 1px;
  background: rgba(255, 255, 255, 0.15);
  z-index: 2;
}
.heading-txt-slider {
  font-size: 70px;
  font-weight: bold;
  color: white;
}
.middle-heading-txt {
  font-size: 70px;
  font-weight: 500;
}
.carousel-control-prev,
.carousel-control-next {
  display: none;
}
.carousel-indicators {
  display: none;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 21.25rem !important;
  left: 3%;
  padding-top: 0px !important;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: left;
}
.dis-contents {
  display: contents;
}
.circle-line {
  position: absolute;
  z-index: 2;
  top: 170px;
}
.slider-image {
  max-width: 100%;
  height: auto;
}

/* Media queries for smaller screens */
@media (min-width: 1300px) and (max-width: 1440px) {
  .heading-txt-slider {
    font-size: 70px;
  }
  .middle-heading-txt {
    font-size: 70px;
  }
  .carousel-caption {
    bottom: 10rem !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .heading-txt-slider {
    font-size: 50px;
  }
  .middle-heading-txt {
    font-size: 50px;
  }
  .w-100 {
    width: 100% !important;
    height: 500px;
  }
  .contact__form {
    top: 0.8%;
  }
  .enquire-now {
    font-weight: 700;
    padding-bottom: 0px;
    font-size: 16px;
  }
  .heading-txt-slider {
    font-size: 26px;
  }
  .middle-heading-txt {
    font-size: 26px;
  }
  .carousel-caption {
    bottom: 10.25rem !important;
  }
  img.d-block.w-100 {
    height: 490px !important;
}
}

@media (max-width: 576px) {
  .heading-txt-slider {
    font-size: 20px;
  }
  .middle-heading-txt {
    font-size: 20px;
  }
}
@media (min-width: 425px) and (max-width: 598px) {
  .heading-txt-slider {
    font-size: 20px;
  }
  .middle-heading-txt {
    font-size: 20px;
  }
  .carousel-caption {
    bottom: 1rem !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .heading-txt-slider {
    font-size: 25px;
  }
  .middle-heading-txt {
    font-size: 25px;
  }
  .carousel-caption {
    left: 37px;
    bottom: 4rem !important;
  }
  img.d-block.w-100 {
    height: 308px !important;
}
.pt-80 {
  padding-top: 600px;
  padding-bottom: 80px 
}
.col-xxl-6.col-xl-6.col-lg-6.dholera-top {
  width: 100%;
}
.brand__item {
  padding: 0px 0;
  border-left: 1px solid var(--white-3);
}
.testimonial__area-4 {
  overflow: hidden;
  text-align: center;
  padding-top: 0px;
}
}

@media (min-width: 1024px) and (max-width: 1600px) {
  .heading-txt-slider {
    font-size: 40px !important;
  }
  .middle-heading-txt {
    font-size: 40x !important;
  }
  .carousel-caption {
    left: 37px;
    bottom: 10rem !important;
  }
  .middle-heading-txt {
    font-size: 40px;
    font-weight: 500;
  }
  img.d-block.w-100 {
    height: 590px;
  }
  .contact__form {
    position: absolute !important;
    z-index: 9;
    top: 0px;
    left: 61.5%;
    top: 1% !important;
    background-color: #fffffff2;
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    width: 32.5%;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .enquire-now {
    font-weight: 700;
    padding-bottom: 0px;
    font-size: 25px;
  }
  .wc-btn-primary, .wc-btn-black, .wc-btn-light, .wc-btn-pink, .wc-btn-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 110px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    text-transform: capitalize;
    color: var(--black-3);
    border: 1px solid var(--gray);
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
}
}

@media (max-width: 1440px) {
  img.d-block.w-100 {
    height: 690px;
}
}
