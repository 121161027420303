/*----------------------------------------*/
/*  19. WORKFLOW CSS START
/*----------------------------------------*/


.workflow {
  &__area {
    overflow: hidden;

    .sec-title {
      &-wrapper {
        text-align: center;
        padding-bottom: 60px;
      }
    }

    &-3 {
      overflow: hidden;
      background-color: var(--pink-4);
      padding-top: 60px;
      direction: ltr;

      @media #{$lg} {
        padding-top: 90px;
        background-color: var(--pink-3);
      }

      @media #{$md} {
        padding-top: 80px;
        background-color: var(--pink-3);
      }

      @media #{$sm} {
        padding-top: 60px;
        background-color: var(--pink-3);
      }

      .wf_panel {
        width: 100%;
        height: 100%;
        will-change: transform;
        opacity: 1 !important;
      }
    }

    &-6 {
      overflow: hidden;
      padding-top: 5px;

      .pb-130 {
        @media #{$sm} {
          padding-bottom: 20px;
        }
      }
    }
  }

  &__wrapper {

    &-3 {
      width: 400%;
      height: 100%;
      display: flex;
      flex-wrap: no-wrap;

      @media #{$lg} {
        width: 100%;
        height: auto;
        flex-wrap: wrap;
      }

      @media #{$md} {
        width: 100%;
        height: auto;
        flex-wrap: wrap;
      }

      @media #{$sm} {
        width: 100%;
        height: auto;
        flex-wrap: wrap;
      }
    }

    &-6 {
      border-top: 1px solid var(--white-4);
      padding-top: 50px;

      @media #{$sm} {
        border-top: none;
        padding-top: 0;
      }
    }
  }

  &__slide {
    text-align: center;
    position: relative;

    @media #{$lg} {
      padding: 0 10px;
    }

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      left: 0;
      top: 58px;
      background-color: var(--black-2);
    }

    &::after {
      position: absolute;
      content: "";
      width: 18px;
      height: 18px;
      left: calc(50% - 9px);
      top: 50px;
      background-color: var(--primary);
      border: 3px solid var(--white);
      border-radius: 100%;
      animation: wcBubble 1.5s 1s infinite;
      transition: all 0.3s;
    }

    p {
      max-width: 230px;
      margin: 0 auto;
    }

    &-6 {
      max-width: 270px;
      position: relative;

      @media #{$sm} {
        max-width: 100%;
        padding-bottom: 40px;
      }

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        left: 0;
        top: -54px;
        background-color: var(--black);
        border: 1px solid var(--white);
        border-radius: 100%;
        animation: wcBubble 1.5s 1s infinite;
        transition: all 0.3s;

        @media #{$sm} {
          width: 0;
          height: 0;
        }
      }
    }
  }

  &__step {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.3;
    color: var(--black-2);
    text-transform: capitalize;
    padding-bottom: 50px;
  }

  &__number {
    font-size: 85px;
    font-weight: 500;
    color: var(--gray-4);
  }

  &__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 1.2;
    color: var(--black-2);
    padding-bottom: 15px;

    &-6 {
      font-weight: 500;
      font-size: 22px;
      line-height: 1.25;
      color: var(--black);
      padding-bottom: 15px;

      @media #{$md} {
        font-size: 20px;
      }

      @media #{$sm} {
        padding-bottom: 10px;
      }
    }
  }


}


// Why Choose us
.choose {
  &-title {
    font-weight: 500;
    font-size: 200px;
    line-height: 1;
    text-transform: uppercase;
    color: var(--black);

    @media #{$xl} {
      font-size: 160px;
      margin-top: -100px;
    }

    @media #{$lg} {
      font-size: 48px;
    }

    @media #{$md} {
      font-size: 40px;
    }

    @media #{$sm} {
      font-size: 40px;
    }

    &-wrapper {
      text-align: center;
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
  }
}

// Research Area
.research {
  &__area {
    background-color: var(--pink-3);
    background-image: url(../../imgs/shape/8.png);
    background-repeat: no-repeat;
    background-position: 200px calc(100% - 150px);

    @media #{$xxl} {
      background-position: 20px calc(100% - 230px);
    }

    @media #{$xl} {
      background-position: 20px calc(100% - 220px);

      &.pt-150 {
        padding-top: 130px;
      }
    }

    @media #{$lg} {
      background-position: 20px calc(100% - 100px);
      padding-bottom: 50px;

      &.pt-150 {
        padding-top: 70px;
      }
    }

    @media #{$md} {
      padding-bottom: 40px;
      background-position: 0 210px;
      background-size: 100px;
    }

    @media #{$sm} {
      background-size: 0;
    }

    &.pt-150 {
      @media #{$md} {
        padding-top: 60px;
      }
    }

    .sec {
      &-title {
        text-transform: uppercase;
        padding-bottom: 50px;

        @media #{$xl} {
          padding-bottom: 20px;
        }

        @media #{$lg} {
          font-size: 40px;
        }

        @media #{$md} {
          padding-bottom: 30px;
        }

        &-wrapper {
          padding-right: 80px;
          padding-bottom: 100px;

          @media #{$xl} {
            padding-right: 40px;
            padding-bottom: 50px;
          }

          @media #{$lg} {
            padding-bottom: 40px;
            padding-right: 20px;
          }

          @media #{$md} {
            padding-bottom: 40px;
          }

          @media #{$sm} {
            padding-right: 0;
            padding-bottom: 40px;
            text-align: center;
          }

          p {
            max-width: 360px;
            font-size: 18px;
            line-height: 1.4;
            color: var(--black-2);
            margin-left: 80px;

            @media #{$lg} {
              margin-left: 0;
            }

            @media #{$md} {
              max-width: 100%;
            }

            @media #{$sm} {
              margin: 0 auto;
            }

          }
        }
      }

      &-sub-title {
        font-weight: 500;
        text-transform: uppercase;
        color: var(--black);
        padding-bottom: 140px;

        @media #{$xxl} {
          padding-bottom: 50px;
        }

        @media #{$xl} {
          padding-bottom: 40px;
        }

        @media #{$lg} {
          display: none;
        }

        @media #{$md} {
          display: none;
        }

        @media #{$sm} {
          display: none;
        }
      }
    }
  }

  &__list {
    @media #{$sm} {
      padding-bottom: 35px;
    }
  }

  &__tools {
    padding-left: 80px;

    @media #{$lg} {
      padding-left: 0;
      padding-bottom: 60px;
    }

    @media #{$md} {
      padding-bottom: 60px;
    }

    @media #{$sm} {
      padding-left: 0;
      padding-bottom: 40px;
      text-align: center;
    }

    li {
      display: inline-block;

      a {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
        text-transform: uppercase;
        color: var(--black);
        border: 1px solid var(--black);
        border-radius: 30px;
        padding: 2px 15px;

        &:hover {
          color: var(--white);
          background-color: var(--black-2);
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 30px;
    padding-bottom: 50px;

    @media #{$xxl} {
      padding-bottom: 30px;
    }

    @media #{$xl} {
      padding-bottom: 15px;
    }

    @media #{$sm} {
      justify-content: center;
    }

    &:nth-child(odd) {
      padding-left: 100px;

      @media #{$xl} {
        padding-left: 80px;
      }

      @media #{$lg} {
        padding-left: 50px;
      }

      @media #{$sm} {
        padding-left: 0;
      }
    }

    p {
      max-width: 440px;
      color: var(--black-2);
    }
  }

  &__number {
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 48px;
      line-height: 1.1;
      text-transform: uppercase;
      color: var(--black-2);
      width: 190px;
      height: 190px;
      border: 3px solid;
      border-radius: 100%;

      @media #{$lg} {
        width: 160px;
        height: 160px;
      }

      @media #{$xl} {
        width: 160px;
        height: 160px;
      }

      @media #{$sm} {
        width: 130px;
        height: 130px;
        font-size: 36px;
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 1.4;
    text-transform: uppercase;
    color: var(--black-2);
    padding-bottom: 15px;

    @media #{$xl} {
      padding-bottom: 5px;
    }
  }

}