.disclaimer-container{
    margin-top: 150px;
    margin-bottom: 100px;
    padding: 50px;
}
.disclaimer-txt-p{
    color: var(--black-3);
    font-size: 18px;
    font-weight: 400;
    line-height: 1.8;
    padding-bottom: 19px;
    text-align: left;
}
.disclaimer-txt-heading-main{
    color: var(--black-2);
    font-size: 45px;
    font-weight: 500;
    line-height: 65px;
    padding-bottom: 15px;
}