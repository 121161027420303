
.testimonial__slider-4 .prev-button:hover, .testimonial__slider-4 .next-button:hover {
    color: #ad042b;
}
.voice-txt{
    font-weight: 600;

}
@media (min-width: 320px) and (max-width: 425px){
    .testimonial__area-4 {
        overflow: hidden;
        text-align: center;
        padding-top: 0px;
    }
    .sec-title-6 {
        line-height: 48px;
    }
    .home-wrapper-4 {
        padding-left: 15px;
        padding-right: 15px;
    }
    .testimonial__slide-4 {
        background-color: var(--gray-22);
        /* padding: 50px 50px 44px 50px; */
        text-align: center;
    }
    .testimonial__info-4 p {
        color: var(--black-3);
        font-weight: 400;
        line-height: 1.8;
        font-size: 18px;
        padding-bottom: 19px;
        text-align: center;
    }
}
@media (min-width: 426px) and (max-width: 500px) {
    .testimonial__area-4 {
        overflow: hidden;
        text-align: center;
        padding-top: 0px;
    }
    .sec-title-6 {
        line-height: 48px;
    }
    .home-wrapper-4 {
        padding-left: 15px;
        padding-right: 15px;
    }
    .testimonial__slide-4 {
        background-color: var(--gray-22);
        /* padding: 50px 50px 44px 50px; */
        text-align: center;
    }
    .testimonial__info-4 p {
        color: var(--black-3);
        font-weight: 400;
        line-height: 1.8;
        font-size: 18px;
        padding-bottom: 19px;
        text-align: center;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    h3.sec-title-6.title-anim {
        line-height: 48px;
    }
    .testimonial__sec-title {
        padding-bottom: 0px;
    }
    .testimonial__area-4 {
        overflow: hidden;
        text-align: center;
        padding-top: 0px;
    }
    .testimonial__slide-4 {
        padding: 30px 17px 17px 17px;
    }
    .home-wrapper-4 {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 1024px) and (max-width: 1600px) {
    .testimonial__area-4 {
        overflow: hidden;
        text-align: center;
        padding-top: 0px;
    }
}